@import "global/styles/functions.scss";

.feedback {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 570px;
  padding: 32px;

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    color: #000;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.22px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__grade {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
  }

  &__userInfo {
    display: flex;
    gap: 8px;

    >div {
      flex: 1;
    }

    .inputs {
      padding: 24px 14px 6px;

      ~label {
        left: 14px !important;
      }

      &:focus~label,
      &:active~label {
        top: 12px !important;
        left: 14px !important;
        font-size: 10px !important;
      }
    }
  }

  &__roolsLink,
  &__rools {
    display: inline-block;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.28px;
  }

  &__roolsLink {
    color: var(--color-brand-primary-shade, #029AAD);
    text-decoration: none;
  }

  .closeBtn {
    display: inline-flex;
    width: 44px;
    height: 44px;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: inherit;
    border-radius: 100%;

    &:hover {
      color: #202020;
      background: hsla(0, 0%, 60%, .1);
    }
  }

  @media (max-width: 768px) {
    overflow-y: scroll;
    gap: getVwM(24px);
    width: 90vw;
    padding: 16px;

    &__container {
      gap: 8px;
    }

    &__userInfo {
      flex-direction: column;

      .inputs {
        &~label {
          font-size: 14px;
        }
      }
    }
  }
}

.errorInput {
  background: url("data:image/svg+xml;utf8,<svg fill='rgb(248, 65, 71)' width='16' height='14' viewBox='0 0 14.56 12.98' xmlns='http://www.w3.org/2000/svg'><path d='m14.42 11.49-6.27-10.99a1 1 0 0 0 -1.74 0l-6.28 11a1 1 0 0 0 .87 1.5h12.56a1 1 0 0 0 .86-1.51zm-6.14-.49a1 1 0 1 1 -2 0v-1a1 1 0 1 1 2 0zm0-4a1 1 0 1 1 -2 0v-3a1 1 0 0 1 2 0z'/></svg>") right 12px center no-repeat #fff;
  border-color: red !important;
  padding-right: 40px;
}

.errorText {
  display: block;
  color: red;
  font-size: 11px;
  line-height: 1;
  margin-top: 4px;
  text-align: left;
  width: 100%;
}

.textareaWrapper {
  position: relative;
  margin-top: 16px;
  margin-bottom: 8px;

  label {
    position: absolute;
    top: 16px;
    left: 14px;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    pointer-events: none;
    transition: 0.3s;
    color: var(--color-location);
  }

  @media (max-width: 768px) {
    margin-top: 8px;
  }
}

.commentTextField {
  border-radius: 8px;
  border: 1px solid #dee0e6;
  font-size: 14px;
  line-height: 1.2;
  transition: border .2s ease;
  width: 100%;
  color: var(--color-neutrals-black);
  resize: vertical;
  padding: 24px 14px 6px;
  min-height: 60px;
  height: 100px;

  &:focus-visible,
  &:hover {
    outline: none;
  }

  &:focus~label,
  &:active~label {
    top: 8px !important;
    left: 14px !important;
    font-size: 10px !important;
  }

  &.active~label {
    top: 8px !important;
    left: 14px !important;
    font-size: 10px !important;
  }
}

.button {
  width: 50%;
  background-color: var(--color-brand-secondary);
  font-size: 16px;
  line-height: 26px;
  color: var(--color-neutrals-white);
  margin-top: 24px;

  &:hover {
    background-color: var(--color-brand-secondary-shade);
  }

  @media (max-width: 1280px) {
    font-size: getVwD(16px);
    line-height: getVwD(26px);
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-top: getVwM(24px);
    font-size: getVwD(46px);
  }
}

[data-channel="samsung"] .button{
  background-color: var(--color-brand-primary-shade) !important;
  &:hover{
    background-color: var(--color-brand-primary) !important;
  }
  &:active {
    background-color: var(--color-brand-primary) !important;
  }
}
