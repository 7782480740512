.link {
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: #999999;
  text-decoration: none;
  transition: color .2s ease;

  &:hover {
    color: var(--color-brand-secondary);
  }

  @media (max-width: 640px) {
    font-size: 3.2vw;
    line-height: 4.53vw;
  }
}

.item {
  display: flex;
  margin-bottom: 8px;
}

.activeItem {
  color: var(--color-brand-secondary);
}
