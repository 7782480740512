.App {
  position: relative;
  text-align: left;
  font-family: 'Inter', sans-serif;
}
@media (min-width: 1400px) {
  .App .container {
    max-width: 1400px;
  }
}
@media (max-width: 1440px) {
  .App .container {
    max-width: 1400px;
  }
}
@media (max-width: 768px) {
  
}

.easeAll400 {
  transition: all 400ms ease-in-out;
}
.easeAll200 {
  transition: all 200ms ease-in-out;
}

[data-primary-fill]{
  fill:var(--color-brand-primary-shade);
}
[data-secondary-fill]{
  fill:var(--color-brand-secondary-shade);
}
[data-primary-stroke]{
  stroke:var(--color-brand-primary-shade);
}
[data-secondary-stroke]{
  stroke:var(--color-brand-secondary-shade);
}
[data-primary-light-fill]{
  fill:var(--color-brand-primary);
}
[data-secondary-light-fill]{
  fill:var(--color-brand-secondary);
}
[data-primary-light-stroke]{
  stroke:var(--color-brand-primary);
}
[data-secondary-light-stroke]{
  stroke:var(--color-brand-secondary);
}