.searchContainer {
  flex: 2;

  @media (max-width: 1080px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    padding: 4px 16px 12px;
    border-bottom: 1px solid #e3e2e7;

    &__wrapper {
      margin-bottom: 0;
    }
  }
}

.wrapper {
  position: relative;

  @media (max-width: 1080px) {
    width: 100%;
  }
}

.input {
  width: 100%;
  height: 48px;
  background: var(--color-neutrals-white);
  border: 1px solid var(--color-neutrals-white);
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--color-Mine-Shaft);
  caret-color: var(--color-brand-primary);
  padding: 13px 16px;
  transition:
    border 0.15s ease,
    box-shadow 0.15s ease;

  @media (max-width: 1080px) {
    border: 1px solid #D4D4D4;
  }

  &:hover,
  &:focus-visible {
    border-color: var(--color-brand-primary-shade);
    box-shadow: inset 0 0 0 1px var(--color-brand-primary-shade);
    outline: none;
  }

  &[type="search"]::-webkit-search-cancel-button {
    display: none;
  }

  @media (max-width: 1200px) {
    height: 42px;
    font-size: 14px;
  }

  @media (max-width: 1080px) {
    padding: 13px 40px;

    &:hover,
    &:focus-visible {
      border-color: #D4D4D4;
      box-shadow: none;
      outline: none;
    }
  }
}

.button {
  height: 100%;
  background: transparent;
  position: absolute;
  top: 0;
  right: 13px;
  border: none;
  padding: 0;
  transition-duration: 0.15s;
  z-index: 10;

  @media (max-width: 1080px) {
    z-index: 8;
  }

  & > svg {
    fill: var(--color-brand-primary-shade);
  }

  @media (max-width: 1080px) {
    position: absolute;
    top: 0;
    left: 12px;
    right: auto;
    height: 100%;
    border: none;
    padding: 0;
    transition-duration: 0.15s;
    z-index: 9;
    background: transparent;

    & > svg {
      fill: #949494;
    }
  }
}

.close__icon {
  position: absolute;
  top: 0;
  right: 12px;
  height: 100%;
  border: none;
  padding: 0;
  transition-duration: 0.15s;
  z-index: 9;
}

.close__btn {
  font-size: 14px;
  line-height: 18.2px;
  color: var(--color-brand-secondary);
}
