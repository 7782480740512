@import "global/styles/functions.scss";
$assetPath: "/assets/icons";

.button {
  .item {
    display: block;
    width: 100%;
    height: 43px;
    padding: 10px 16px;
    border: none;
    border-radius: 0;
    outline: none;

    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-Tundora, #424242);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;

    &:active {
      background: var(--color-Wild-Sand, #F5F5F5);
    }

    &.active {
      background: var(--color-Wild-Sand, #F5F5F5);
    }
  }

  button {
    position: relative;
    width: 100%;
    height: 50px;
    padding: 8px 24px;
    border: 1px solid #E3E2E7;
    border-radius: 8px;
    background-color: transparent;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-neutrals-black) !important;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media (max-width: 640px) {
      height: 12vw;
      padding: 4vw 5vw;
      font-size: 3.7333333333vw;
      line-height: normal;
    }

    &:active {
      color: var(--color-neutrals-black) !important;
    }

    &::after {
      position: absolute;
      right: 24px;
      top: 0;
      bottom: 0;
      margin: auto !important;
      background: url(#{$assetPath}/cards/curve-down.svg) no-repeat;
      background-size: contain;
      height: 10px;
      width: 14px;
      transition: .2s;
    }

    &[aria-expanded=true] {
      border-radius: 8px 8px 0 0;
      // color: var(--color-brand-secondary);
      background-color: transparent;
      box-shadow: 0 0.25rem 1.25rem rgba(0, 0, 0, .12);
      border-color: var(--color-neutrals-border-solid);
      border-bottom-color: transparent;
      z-index: 3;

      &:hover {
        // color: var(--color-brand-secondary);
        border-color: var(--color-neutrals-border-solid);
      }

      &::after {
        transform: scale(-1);
      }
    }

    &:hover {
      color: var(--color-neutrals-black);
      background-color: transparent;
      border-color: var(--color-brand-secondary);
    }

    &:active {
      color: var(--color-neutrals-black) !important;
      background-color: transparent !important;
      border-color: var(--color-neutrals-border-solid) !important;
    }
  }
}

.active {
  background: var(--color-neutrals-border-solid);
  font-weight: 600;
  color: var(--color-neutrals-black);
}

.select {
  button {
    width: 100%;
    height: 48px;
    padding: 0 24px;
    border: 1px solid #E3E2E7;
    border-radius: 8px;
    background-color: transparent;
    font-size: 16px;
    font-weight: 400;
    color: var(--color-neutrals-black);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;

    @media (max-width: 1280px) {
      height: getVwD(48px);
      padding: 0 getVwD(24px) 0 getVwD(14px);
      font-size: getVwD(16px);
    }

    @media (max-width: 768px) {
      height: 13.3vw;
      padding: 0 6.4vw 0 3.73vw;
      font-size: 3.73vw;
    }

    &:after {
      content: "";
      background: url(#{$assetPath}/cards/curve-down.svg) no-repeat;
      background-size: contain;
      background-position: center;
      position: absolute;
      right: 16px;
      top: 0;
      bottom: 0;
      margin: auto !important;
      pointer-events: none;
      height: 10px;
      width: 14px;
      transition: transform .2s ease;
    }

    &:focus {
      border: 1px solid #E3E2E7;
    }

    &[aria-expanded=true] {
      border-radius: 8px 8px 0 0;
      color: var(--color-brand-secondary);
      background-color: transparent;
      box-shadow: 0 0.25rem 1.25rem rgba(0, 0, 0, .12);
      border-color: var(--color-neutrals-border-solid);
      border-bottom-color: transparent;
      z-index: 3;

      &:hover {
        color: var(--color-neutrals-black);
        border-color: var(--color-neutrals-border-solid);
        background-color: transparent;
      }

      &::after {
        background: url(#{$assetPath}/cards/curve-down.svg) no-repeat;
        background-size: contain;
        transform: scale(-1);
        height: 10px;
        width: 14px;
      }
    }

    &:hover {
      color: var(--color-neutrals-black);
      background: #F2F2F3;
      border-color: #E3E2E7;
    }

    &:active {
      color: var(--color-brand-secondary) !important;
      background-color: transparent !important;
      border-color: var(--color-neutrals-border-solid) !important;
    }
  }

  &__catalog button:after {
    content: none;
  }
}

.option {
  width: 100%;
  height: 100%;
  padding: 12px 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--color-neutrals-black);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 1280px) {
    font-size: getVwD(14px);
    padding: getVwD(12px) getVwD(24px);
  }

  @media (max-width: 768px) {
    font-size: 3.2vw;
    padding: 2.67vw 4.8vw;
  }

  &:active {
    background: var(--color-neutrals-border-solid);
    color: var(--color-neutrals-black);
  }
}

.textGray {
  >button {
    color: var(--color-location);
  }
}
