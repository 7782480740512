.sliderWrapper {
  position: relative;
  height: auto;
  padding: 32px;
}

.sliderContainer {
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    max-width: 80vw;
  }
}

.zoomSlide {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin-top: 0 !important;

  &>img {
    max-height: 400px;
  }

  &:hover {
    background-size: 100%;

    &>img {
      opacity: 0;
    }
  }
}

.thumbSlide {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  padding: 4px;
  cursor: pointer;
  border: 1px solid #00000000;
  border-radius: 3px;

  &>img {
    height: 100%;
    object-fit: contain; 
    max-width: 100%;
    max-height: 100%;
  }
}


.thumbsActive {
  border: 1px solid var(--color-brand-secondary, #FB791B);
}

.thumbsContainer {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 450px;
  margin-top: 24px;
  padding: 0 28px;
}

.thumbsDisabledBtn {
  opacity: .6;
}

.prev,
.next {
  position: absolute;
  cursor: pointer;
  height: 48px;
  display: inline-flex;
  align-items: center;

  &:hover {
    background-color: inherit !important;
    box-shadow: none !important;
  }

  &>svg {
    fill: var(--color-brand-secondary, #FB791B);
  }

  @media (max-width: 768px) {
    top: 0;
    bottom: 0;
    margin: auto 0;
    z-index: 2;

    &>svg {
      fill: var(--color-brand-primary-shade);
      width: 50px;
      height: 50px;
    }
  }
}

.prev {
  left: 0;
}

.next {
  right: 0;
}
