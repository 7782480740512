@import "global/styles/functions.scss";

.document {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  column-gap: 25px;
  row-gap: 16px;

  @media (max-width: 1280px) {
    column-gap: getVwD(25px);
    row-gap: getVwD(16px);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: getVwM(16px);
    margin-bottom: getVwM(16px);
  }

  &__wrapper {
    display: flex;
    align-items: center;
    gap: 12px;

    @media (max-width: 1280px) {
      gap: getVwD(12px);
    }

    @media (max-width: 768px) {
      gap: getVwM(12px);
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 80px;
    background-color: var(--color-neutrals-turquoise);
    border-radius: 8px;

    >svg {
      @media (max-width: 1280px) {
        width: getVwD(40px);
        height: getVwD(48px);
      }
  
      @media (max-width: 768px) {
        width: getVwM(40px);
        height: getVwM(48px);
      }
    }

    @media (max-width: 1280px) {
      width: getVwD(120px);
      height: getVwD(80px);
    }

    @media (max-width: 768px) {
      width: getVwM(120px);
      height: getVwM(80px);
    }
  }

  &__title {
    font-size: 16px;
    font-weight: 400;
    line-height: 130%;
    color: var(--color-neutrals-black);

    margin-bottom: 8px;

    @media (max-width: 1280px) {
      font-size: getVwD(16px);
      margin-bottom: getVwD(8px);
    }

    @media (max-width: 768px) {
      font-size: getVwM(14px);
      margin-bottom: getVwM(8px);
    }
  }

  &__download {
    display: flex;
    align-items: center;
    gap: 4px;
    text-decoration: none;

    >svg {
      @media (max-width: 1280px) {
        width: getVwD(20px);
        height: getVwD(21px);
      }
  
      @media (max-width: 768px) {
        width: getVwM(20px);
        height: getVwM(21px);
      }
    }
    
    &:hover {
      color: var(--color-brand-primary-shade);
      text-decoration: underline;
    }

    > span {
      font-size: 16px;
      font-weight: 500;
      line-height: 130%;
      color: var(--color-brand-primary-shade);

      @media (max-width: 1280px) {
        font-size: getVwD(16px);
      }
  
      @media (max-width: 768px) {
        font-size: getVwM(14px);
      }
    }
  }
}
