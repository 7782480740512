@import "global/styles/functions.scss";

.helpButton {
  position: relative;
  display: inline-block;
}

.icon {
  width: 20px;
  height: 20px;
  fill: var(--color-Mischka);
  cursor: pointer;
  transition: fill .2s;

  &:hover {
    fill: var(--color-brand-secondary-shade);
  }
  &.active {
    fill: var(--color-brand-secondary-shade);
  }

  @media (max-width: 1280px) {
    width: getVwD(20px);
    height: getVwD(20px);
  }

  @media (max-width: 768px) {
    width: 5.33vw;
    height: 5.33vw;
  }
}

.popup {
  width: 250px;
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translate(-50%, 0);
  margin: auto;
  z-index: 1;
}

.content {
  position: relative;
  background-color: var(--color-neutrals-grey);
  padding: 7px 14px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-Emperor);

  &::before {
    content: "";
    position: absolute;
    top: -9px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 18px;
    height: 18px;
    transform: rotate(45deg);
    background-color: var(--color-neutrals-grey);
  }
}
