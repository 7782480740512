@import "global/styles/functions.scss";
$assetPath: "/assets/icons";

.preorder {
  width: 530px;
  max-height: 95vh;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px;
  overflow-y: auto;

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    color: #000;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.22px;
  }

  &__productInfo {
    display: flex;
    gap: 24px;

    img {
      height: 80px;
    }
  }

  &__productContent {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__description {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-decoration: none;
    color: var(--color-neutrals-black);
    overflow: hidden;

    @media(max-width: 880px) {
      font-size: 14px;
    }
  }

  &__info {
    // padding: 12px;
    border-top: 1px dashed #e0e0e0;
    border-bottom: 1px dashed #e0e0e0;
  }

  &__termsOfUse, &__termsOfUse a {
    color: var(--color-location);
    font-size: 12px;
    line-height: 1.25;

    a {
      font-weight: 600;
      text-decoration: none;
    }
  }

  @media (max-width: 768px) {
    gap: 16px;
    width: 80vw;
    padding: 32px;
  }

  @media (max-width: 640px) {
    gap: 4px;
    width: 80vw;
    min-width: calc(280px - 32px);
    padding: 16px;
  }
}

.closeBtn {
  display: inline-flex;
  width: 44px;
  height: 44px;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: inherit;
  border-radius: 100%;
  margin-right: -12px;
  padding: 0;

  &:hover {
    color: #202020;
    background: hsla(0, 0%, 60%, .1);
  }
}

.orangeBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-top: 12px;
  color: var(--color-neutrals-white);
  background-color: var(--color-brand-secondary);

  &:hover {
    background-color: var(--color-brand-secondary-shade);
  }

  &:active {
    background-color: var(--color-brand-secondary-shade);
  }

  &:disabled {
    color: #202020;
    background-color: #FFC39C;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__container {
    display: flex;
    gap: 16px;

    &>div {
      flex: 1;
    }
  }
}

.errorText {
  display: block;
  color: red;
  font-size: 11px;
  line-height: 1;
  margin-top: 4px;
  text-align: left;
  width: 100%;
}

.errorInput {
  background: url("data:image/svg+xml;utf8,<svg fill='rgb(248, 65, 71)' width='16' height='14' viewBox='0 0 14.56 12.98' xmlns='http://www.w3.org/2000/svg'><path d='m14.42 11.49-6.27-10.99a1 1 0 0 0 -1.74 0l-6.28 11a1 1 0 0 0 .87 1.5h12.56a1 1 0 0 0 .86-1.51zm-6.14-.49a1 1 0 1 1 -2 0v-1a1 1 0 1 1 2 0zm0-4a1 1 0 1 1 -2 0v-3a1 1 0 0 1 2 0z'/></svg>") right 12px center no-repeat #fff;
  border-color: red !important;
  padding-right: 40px;
}

.select {
  display: grid;
  height: 50px !important;

  button {
    width: 100%;
    height: 100%;
    padding: 0 24px;
    border: 1px solid #E3E2E7;
    border-radius: 8px;
    background-color: transparent;
    font-size: 16px;
    font-weight: 400;
    color: var(--color-neutrals-black);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;

    @media (max-width: 1280px) {
      height: getVwD(48px);
      padding: 0 getVwD(24px) 0 getVwD(14px);
      font-size: getVwD(16px);
    }

    @media (max-width: 768px) {
      height: 13.3vw;
      padding: 0 6.4vw 0 3.73vw;
      font-size: 3.73vw;
    }

    &:after {
      content: "";
      background: url(#{$assetPath}/cards/curve-down.svg) no-repeat;
      background-size: contain;
      background-position: center;
      position: absolute;
      right: 16px;
      top: 0;
      bottom: 0;
      margin: auto !important;
      pointer-events: none;
      height: 10px;
      width: 14px;
      transition: transform .2s ease;
    }

    &:focus {
      border: 1px solid #E3E2E7;
    }

    &[aria-expanded=true] {
      border-radius: 8px 8px 0 0;
      color: var(--color-brand-secondary);
      background-color: transparent;
      box-shadow: 0 0.25rem 1.25rem rgba(0, 0, 0, .12);
      border-color: var(--color-neutrals-border-solid);
      border-bottom-color: transparent;
      z-index: 3;

      &:hover {
        color: var(--color-neutrals-black);
        border-color: var(--color-neutrals-border-solid);
        background-color: transparent;
      }

      &::after {
        background: url(#{$assetPath}/cards/curve-down.svg) no-repeat;
        background-size: contain;
        transform: scale(-1);
        height: 10px;
        width: 14px;
      }
    }

    &:hover {
      color: var(--color-neutrals-black);
      background: #F2F2F3;
      border-color: #E3E2E7;
    }

    &:active {
      color: var(--color-brand-secondary) !important;
      background-color: transparent !important;
      border-color: var(--color-neutrals-border-solid) !important;
    }
  }

  &__catalog button:after {
    content: none;
  }
}

.option {
  width: 100%;
  height: 100%;
  padding: 12px 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--color-location);
  text-wrap: wrap;

  @media (max-width: 1280px) {
    font-size: getVwD(14px);
    padding: getVwD(12px) getVwD(24px);
  }

  @media (max-width: 768px) {
    font-size: 3.2vw;
    padding: 2.67vw 4.8vw;
  }

  &:active,
  &.active {
    background: var(--color-neutrals-border-solid);
    color: var(--color-neutrals-black);
  }
}

.textGray {
  >button {
    color: var(--color-location);
  }
}

.oldPriceProcent {
  top: 25% !important;

  @media (max-width: 768px) {
    top: 20% !important;
  }
}
