.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    padding: 16px;
  }
}

.title {
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  color: var(--color-neutrals-black);
  margin-bottom: 16px;

  @media (max-width: 768px) {
    margin: 16px 0;
    font-size: 18px;
    line-height: 24px;
  }
}

.subtitle {
  font-size: 24px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  color: var(--color-neutrals-black);

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 18px;
  }
}

.icon {
  width: 50%;

  @media (max-width: 768px) {
    width: 80%;
    height: auto;
  }
}

.buttonWrapper {
  display: flex;
  align-items: center;
  gap: 64px;
  margin-top: 24px;

  @media (max-width: 768px) {
    gap: 24px;
  }
}

.button {
  color: var(--color-neutrals-white);
  background-color: var(--color-brand-primary);
  
  &:hover {
    background-color: var(--color-brand-primary-shade);
  }
}
