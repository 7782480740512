.badgeItem {
  display: inline-flex;
  align-items: center;
  height: 24px;

  padding: 2px 6px;
  margin-right: 6px;
  border-radius: 4px;

  color: white;

  text-align: center;
  // font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 10px;

  &.badgeImg {
    background-color: var(--color-labels-yellow, #FFCD00);
  }
}

.questionTooltip {
  padding: 16px;
  border-radius: 8px;
  background: var(--color-neutrals-grey, #F2F2F2);

  box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.10), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);

  color: var(--color-Emperor, #4F4F4F);

  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;

  z-index: 1;
  /* 125% */

  &__content {
    position: relative;
    background: inherit;

    &::before {
      content: '';
      position: absolute;
      top: -18px;
      left: 50%;

      width: 18px;
      height: 18px;
      background: var(--color-neutrals-grey, #F2F2F2);
      transform: rotate(45deg);
      z-index: -1;
    }
  }

  p:last-child {
    margin-bottom: 0;
  }
}
