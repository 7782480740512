.wrapper {
  overflow: hidden auto;
  padding-right: 12px;
  margin-right: -12px;
  max-height: 190px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  min-height: 48px;
  width: 100%;
  padding: 6px;
  background: var(--color-brand-primary);
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  color: var(--color-neutrals-white);
  text-decoration: none;

  &:hover {
    background-color: var(--color-brand-primary-shade);
    color: var(--color-neutrals-white);
  }
}

[data-channel="samsung"] .button{
  background-color: var(--color-brand-primary-shade);
  &:hover{
    background-color: var(--color-brand-primary);
  }
}
.productWrapper {
  display: flex;
  gap: 16px;
  padding: 16px 0;

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-neutrals-border-solid);
  }
}

.imageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  padding: 0 9px;
  border-radius: 8px;
  background-color: var(--color-neutrals-turquoise);
}

.image {
  width: -webkit-fill-available;
}

.productImage {
  width: 40px;
  max-height: 40px;
}

.title {
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  color: var(--color-Emperor);
  font-size: 12px;
  line-height: 1.45;
  text-decoration: none;
  -webkit-box-orient: vertical;
  overflow: hidden;

  &:hover {
    color: var(--color-brand-secondary);
  }
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.deleteIcon {
  min-width: 16px;
  cursor: pointer;

  &:hover {
    >path {
      stroke: var(--color-brand-secondary);
    }
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.count {
  color: var(--color-Emperor);
  font-size: 12px;
  line-height: 1.25;
}

.cost {
  color: var(--color-Emperor);
  font-weight: 700;
  font-size: 14px;
  line-height: 1.22;
}

.emptyCart {
  display: flex;
  gap: 24px;
  align-items: center;
}

.emptyCartIcon {
  width: 64px;
  height: 64px;
  > [data-secondary]{
    fill: var(--color-brand-primary-shade) !important;
  }
}
[data-channel='samsung'] .emptyCartIcon {
  > [data-secondary]{
    fill: var(--color-brand-secondary) !important;
  }
}

.emptyCartTitle {
  font-size: 20px;
}

.emptyCartSubtitle {
  font-size: 14px;
  line-height: 18px;
  margin-top: 4px;
}

.cart__mini {
  &_with__gift {
    display: flex;
    align-items: center;
    flex-flow: column;
    flex-shrink: 0;
    border: 2px solid var(--color-brand-secondary);
    border-radius: 12px;
    padding: 2px;
    overflow: hidden;
  }

  &__image {
    position: relative;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
  }

  &_plus__icon {
    align-items: center;
    background: var(--color-neutrals-white);
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
    color: var(--color-location);
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    margin: -4px 0;
    height: 20px;
    width: 20px;
    position: relative;
    z-index: 1;
  }

  &_gift__icon {
    background: var(--color-neutrals-white);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
    border-radius: 50%;
    position: absolute;
    line-height: 0;
    padding: 2px;
    top: 2px;
    right: 2px;

    >svg {
      width: 11px;
      height: 11px;

      >path {
        fill: var(--color-brand-primary-shade);
      }
    }
  }
}
