.link {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  cursor: pointer;
  span {
    width: max-content;
    font-weight: 400;
    font-size: 13.5px;
    line-height: 10px;
    color: #5A595F;
  }
  &:hover {
    span {
      color: var(--color-brand-secondary);
    }
  }
  @media(max-width: 1400px) {
    gap: 4px;
    svg {
      width: 22px;
      height: 22px;
    }
    span {
      font-size: 12px;
    }
  }
  @media(max-width: 1200px) {
    gap: 2px;
    svg {
      width: 20px;
      height: 20px;
    }
    span {
      font-size: 11px;
    }
  }
}

.activeClass {
  >span {
    color: var(--color-brand-secondary);
  }
}
