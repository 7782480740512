@mixin btn {
  border: none;
  padding: 0;
  margin: 0;
  background: inherit;
}

.reviews {
  &_title {
    color: var(--color-Woodsmoke, #0D0E0F);
    font-size: 22px;
    font-family: Inter;
    font-style: normal;
    font-weight: 800;
    line-height: 16px;

    margin-top: 32px;
  }

  &_rating {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 26px 0;
  }

  &_starRating {
    display: inline-flex;
    align-items: flex-start;
    gap: 8px;

    color: var(--color-neutrals-black, #000);
    font-size: 18px;
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.18px;
  }

  &_stars {
    width: 16px;
    height: 16px;
    margin-top: -1px;
  }

  &_btn {
    border: 2px solid var(--color-brand-secondary-shade);

    @media (max-width: 768px) {
      display: block;
      margin: 0 auto;
      margin-top: 16px;
      border-radius: 8px;
      border: 1px solid var(--color-brand-secondary-shade);
      color: var(--color-Tuna, #3C3C43);
      /* Web 1440px/B6 */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.28px;
    }
  }
}

.messages {
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (max-width: 768px) {
    gap: 16px;
  }

  &_container {
    position: relative;

    display: flex;
    gap: 16px;
    padding: 34px 40px;
    margin-left: 25px;
    border-radius: 10px;
    background: var(--evrika-turquoise-shade, #EAF4F6);
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 30px;
      left: -25px;
      width: 0;
      height: 0;
      border-right: 30px solid var(--evrika-turquoise-shade, #EAF4F6);
      border-bottom: 40px solid transparent;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      padding: 12px;
      margin-left: 20px;

      &::before {
        left: -20px;
        border-right: 20px solid var(--evrika-turquoise-shade, #EAF4F6);
        border-bottom: 30px solid transparent;
      }
    }
  }

  &_athorInfo {
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
    }
  }

  &_initials {
    display: flex;
    flex-direction: column;
    color: var(--color-location, #999);
  }

  &_athorName {
    margin-top: 15px;
    font-size: 14px;
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.14px;
  }

  &_publishedDate {
    font-size: 12px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.12px;
  }

  &_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &_text {
    color: var(--color-neutrals-black, #000);
    font-size: 16px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  &_actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &_answer {
    display: flex;
    align-items: center;
    gap: 10px;

    @include btn;

    color: var(--color-brand-primary-shade, #029AAD);
    font-size: 12px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &_praise {
    display: flex;
    gap: 16px;
  }

  &_like,
  &_dislike {
    @include btn;
    display: flex;
    gap: 4px;
    align-items: flex-end;

    color: var(--color-dark-Silver, #C4C4C4);
    font-size: 12px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &>svg {
      fill: none;
      stroke: var(--color-dark-Silver, #C4C4C4);
    }

    &_active {
      color: var(--color-brand-primary-shade, #029AAD);

      &>svg {
        stroke: var(--color-brand-primary-shade, #029AAD);
      }
    }
  }

  &_dislike {
    &>svg {
      transform: rotate(180deg);
    }
  }
}

.review {
  &_answer {
    width: 83%;
    background: #F7F7F7;
    padding: 0px 55px 16px 74px;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
    margin-left: auto;

    &::before {
      border-color: transparent #F7F7F7 transparent transparent;
    }

    @media (max-width: 768px) {
      width: auto;
      margin-left: 20px;
      padding: 6px 12px 12px;
      gap: 8px;
    }
  }
}
