.blueBtn {
  width: 220px;
  color: var(--color-neutrals-white);
  background-color: var(--color-brand-primary);

  &:hover {
    background-color: var(--color-brand-primary-shade);
  }

  &:active {
    background-color: #029AADd8;
  }
}

.loadingBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 220px;
  border: 1px solid var(--color-brand-primary-shade);
} 

.orangeBtn {
  width: 340px;
  color: var(--color-neutrals-white);
  background-color: var(--color-brand-secondary);

  &:hover {
    background-color: var(--color-brand-secondary-shade);
  }

  &:active {
    background-color: #EB8039;
  }

  &:disabled {
    color: #202020;
    background-color: #FFC39C;
  }
}

.borderBtn {
  width: 340px;
  border: 2px solid var(--color-neutrals-border-solid);
  color: #4F4F4F;

  &:hover {
    border-color: var(--color-brand-secondary-shade);
    color: #202020;
  }

  &:active {
    border-color: #EB8039;
    color: #202020;
  }

  &:disabled {
    color: #1C1B1F38;
    border-color: rgba(31, 31, 31, 0.12);
  }
}

.text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.textWithDashed {
  position: relative;
  color: var(--color-brand-primary-shade);
  cursor: pointer;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -4px;
    border-bottom: 1px dashed var(--color-brand-primary-shade);
  }
  &:hover {
    color: var(--color-labels-orange);
    &::before {
      border-bottom: 1px dashed var(--color-labels-orange);
    }
  }
}

.blockWithText {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.iconRight {
  g > path {
    fill: var(--color-brand-primary-shade);
  }
}

.textBlue {
  color: #029AAD;
}

.textWithIcon {
  cursor: pointer;
  &:hover {
    span {
      color: var(--color-labels-orange);
    }
    svg > g > path {
      fill: var(--color-labels-orange);
    }
  }
}

.simpleText {
  color: #000000;
  cursor: pointer;
  &:hover {
    color: var(--color-labels-orange);
  }
}
