.container {
  padding-top: 32px;
  padding-bottom: 32px;
}

.row {
  gap: 32px;

  @media (max-width:1200px) {
    gap: 4px;
  }
}

.title {
  font-weight: 800;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
  margin-bottom: 23px;
  position: relative;

  svg {
    position: absolute;
    right: 0;
  }
}

.wrapper {
  display: flex;
  gap: 16px;
  justify-content: space-between;

  @media (max-width: 1080px) {
    justify-content: center;
    margin: 24px 0;
  }

  @media (max-width: 640px) {
    margin: 6.4vw 0;
    gap: 4.27vw;
  }
}

.icon {
  width: 32px;
  height: 32px;

  @media (max-width: 640px) {
    width: 8.53vw;
    height: 8.53vw;
  }
}

.descr {
  margin-top: 24px;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.25;
  color: #999999;

  @media (max-width: 640px) {
    margin-top: 3.47vw;
    font-size: 3.2vw;
  }
}

.list {
  list-style: none;
  padding: 0;
  margin: 0 0 1.25rem;
}

.contactWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.telLink {
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: #000000;
  text-decoration: none;
  transition: color .2s ease;

  &:hover {
    color: var(--color-brand-secondary);
  }
}

.textTime {
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: #999999;
}

.link {
  display: inline-block;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: var(--color-brand-secondary-shade);
  text-decoration: none;
  transition: color .2s ease;

  @media (max-width: 640px) {
    font-size: 4.27vw;
    line-height: 4.53vw;
  }

  &:hover {
    color: var(--color-brand-primary);
  }
}

.input {
  width: 283px;
  height: 56px;
  border: 1px solid #E0E0E0;
  border-radius: 8px;

  @media (max-width: 1080px) {
    width: 100%;
    height: 50px;
  }

  @media (max-width: 640px) {
    height: 13.33vw;
  }

  &__wrapper {
    position: relative;
  }

  &__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
    cursor: pointer;
    background: transparent;
    border: none;

    &:hover {
      >svg {
        >path {
          fill: var(--color-brand-secondary);
        }
      }
    }
  }
}

.checkboxWrapper {
  display: flex;
  gap: 16px;
  margin: 16px 0;

  >div {
    >label {
      font-weight: 700;
      font-size: 15px;
      line-height: 16px;
      color: #000000;
      cursor: pointer;
    }

    >input {
      cursor: pointer;
    }
  }

}

.containerMob {
  padding: 0 16px 32px;
}

.linkWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
}

.submitBtn {
  text-align: center;
  margin: 16px 0;
  color:#FFF;
  background-color: var(--color-brand-primary);
  &:hover{
    background-color:  var(--color-brand-primary-shade);
    color:#FFF;
  }
}

[data-channel="samsung"] .submitBtn{
  background-color: var(--color-brand-primary-shade);
  &:hover{
    background-color: var(--color-brand-primary);
  }
}
.qrWrap {
  -webkit-box-shadow: 0 0 6px 0px rgba(0,0,0,0.20);
  -moz-box-shadow: 0 0 6px 0px rgba(0,0,0,0.20);
  box-shadow: 0 0 6px 0px rgba(0,0,0,0.20);
  padding: 4px;
  background-color: #FFF;
  overflow: hidden;
  width: 100%;
  max-width: 240px;
  
  img {
    display: block;
    width: 100%;
    height: auto;
    max-width: 220px;
  }
}
.downloadAppTxt {
  font-size: 14px;
  margin-top: 16px;
  color: #999999;
}
.downloadAppMobile {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  margin: 6vw auto;

  img {
    height: 50px;
    width: auto;
  }

  @media (max-width: 640px) {
    justify-content: center;
    img {
      height: 12vw;
      width: auto;
    }
  }
}
