.mainContainer {
  flex: 1;
  // max-width: 932px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  flex-shrink: 0;
  padding: 32px 30px;
  background: var(--neutrals-white, #FFF);
  border-radius: 8px;

  @media (max-width: 768px) {
    margin: 0 -10px;
    padding: 16px;
    border-radius: 0;
  }

  &__upper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__title {
    max-width: 532px;
    color: #000;
    font-size: 22px;
    font-family: 'Inter';
    font-weight: 700;
    letter-spacing: -0.22px;

    @media (max-width: 768px) {
      font-size: 20px;
      line-height: 1.125;
      margin-bottom: 0;
    }
  }

  &__badges {
    display: flex;
    align-items: center;
    gap: 8px;

    @media (max-width: 768px) {
      margin-top: 16px;
    }

    @media (max-width: 480px) {
      flex-wrap: wrap;
    }
  }

  &__brand {
    height: 18px;

    @media (max-width: 768px) {
      max-width: 53px;
      max-height: 15px;
    }

    &_name {
      font-size: 16px;
      font-weight: 700;

      @media (max-width: 768px) {
        font-size: 12px;
        line-height: 1.25;
      }
    }
  }

  &__mainInfo {
    display: flex;
    gap: 36px;

    &_loading {
      gap: 10px;
    }

    @media (max-width: 1150px) {
      flex-direction: column;
      gap: 24px;
    }

    @media (max-width: 768px) {
      gap: 16px;
    }
  }
}

.placeholder {
  display: flex;
  justify-content: space-between;
  margin: 0;

  &__Title {
    height: 34px;
  }

  &__dataContainer {
    height: 28px;
  }

  &__slider {
    width: 100%;
    border-radius: 0 !important;

    @media (max-width: 768px) {
      height: 80vw;
    }
  }

  &__badges {
    margin-top: 16px;
  }

  &__left {
    text-align: right;
  }

  &__block {
    margin-top: 16px;
    height: 50px;
  }

  &__Header {
    height: 28px;
    margin-bottom: 16px;
  }

  &__bonus {
    width: 100px;
  }

  &__specification {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed #3c3c43a8;

    &>span {
      margin: 0;
    }
  }
}

.slider {
  flex: 1;

  @media (max-width: 1150px) {
    position: relative;
  }
}

.dataContainer {
  flex: 1.2;
  margin-top: 16px;

  @media (max-width: 768px) {
    margin-top: 0;
  }
}

// rewiews
.rewiewsContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.rewiews {
  display: flex;
  align-items: center;
  gap: 4px;

  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;

  color: var(--color-Woodsmoke);
}

.rewiewsStar {
  height: 16px;
  fill: var(--color-Marigold-Yellow);
}

.writeReviews,
.rewiewsLink {
  border: none;
  background-color: inherit;
  margin-left: 8px;
  text-decoration: none;

  font-size: 13px;
  font-weight: 400;

  color: var(--color-brand-primary-shade);

  &:hover {
    color: var(--color-brand-primary-shade);
  }
}

.writeReviews {
  margin-left: 0;
  border: none;
  background-color: inherit;
  padding: 0;
}

.productCode {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;

  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 1.25;
  }
}

// color blocks
.colorContainer {
  margin: 16px 0;
}

.Title {
  margin-bottom: 10px;

  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  color: var(--color-neutrals-black, #000);

  @media (max-width: 768px) {
    font-size: 14px;
    margin-bottom: 16px;
  }
}

.colorBlocks {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 8px;
}

.colorBlock {
  position: relative;
  width: 48px;
  height: 48px;
  padding: 4px;
  border-radius: 3px;
  border: 1px solid var(--color-neutrals-white, #fff);
  cursor: pointer;

  &:hover {
    border: 1px solid var(--color-brand-secondary-shade, #FB791B);
  }

  &>img {
    max-height: 100%;
    max-width: 100%;
  }

  &.loading {
    pointer-events: none;
  }

  @media (max-width: 768px) {
    width: 56px;
    height: 56px;
  }

  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0000004a;
    z-index: 3;
    border-radius: 3px;
  }
}

.colorBlockActive {
  border: 1px solid var(--color-brand-secondary-shade, #FB791B);
}

.colorBlockDisabled {
  border-color: var(--color-neutrals-border-solid, #E0E0E0);
  color: var(--color-neutrals-black, #000);
  background-color: rgb(170, 170, 170);
  cursor: not-allowed;
}

.colorPoint {
  position: absolute;
  right: 6px;
  bottom: 6px;
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  border: 1px solid var(--color-neutrals-border-solid, #E0E0E0);
}

// info 
.infoBlocks {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 5px;
}

.infoBlock {
  position: relative;
  display: inline-block;
  overflow: hidden;

  padding: 8px 10px;
  border-radius: 18px;
  border: 1px solid var(--color-neutrals-border-solid, #E0E0E0);

  background: var(--color-neutrals-white, #FFF);
  color: var(--color-Tuna, #3C3C43);

  font-size: 13px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;

  cursor: pointer;

  &:hover {
    border-color: var(--color-brand-secondary-shade, #FB791B);
  }

  @media (max-width: 768px) {
    padding: 7px 10px;
  }
}

.infoBlockActive {
  border-color: var(--color-brand-secondary-shade, #FB791B);
  color: var(--color-neutrals-black, #000);
}

.infoBlockDisabled {
  border-color: var(--color-neutrals-border-solid, #E0E0E0);
  color: var(--color-location);
  background-color: #F8F8F8;
  cursor: not-allowed;
}

// characteristics
.characteristicsContainer {
  margin-top: 26px;

  @media (max-width: 768px) {
    display: none;
  }
}

.characteristics {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.gift {
  margin-top: 12px;
  display: flex;
  align-items: center;

  border-radius: 8px;
  border: 4px solid var(--color-Energy-Yellow, #F8D548);
  padding: 12px 23px;

  @media (max-width: 480px) {
    flex-wrap: wrap;
  }

  &_img {
    max-height: 76px;
    flex-shrink: 0;
  }

  &_title {
    flex: 1.5;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-left: 4px;

    &_head {
      color: var(--color-brand-secondary-shade, #FB791B);
      font-size: 13px;
      font-family: Inter;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    &_description {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;

      font-size: 14px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      color: var(--color-neutrals-black, #000);
    }

    &_price {
      color: var(--color-brand-secondary-shade, #FB791B);
      font-size: 19px;
      font-family: Inter;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0.5px;
    }

    &_oldPrice {
      margin-right: 8px;
      color: var(--color-location, #999);
      font-size: 12px;
      font-family: Inter;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0.5px;
      text-decoration-line: line-through;

      @media (max-width: 1280px) {
        display: inline-block;
        width: 100%;
      }
    }
  }

  &_check {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-left: 16px;

    &_head {
      color: var(--color-location, #999);
      font-size: 12px;
      font-family: Inter;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.5px;
      margin-left: 4px;
    }

    &_btn {
      padding: 10px;
      border-radius: 8px;
      background: var(--color-brand-secondary-shade, #FB791B);
      color: var(--color-neutrals-white, #FFF);

      text-align: center;
      font-size: 12px;
      font-family: Inter;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }

  &_close_btn {
    padding: 10px;
    border: 2px solid var(--color-neutrals-border-solid);
    border-radius: 8px;
    color: #4F4F4F;

    text-align: center;
    font-size: 12px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;

    &:hover {
      border-color: var(--color-brand-secondary-shade);
      color: #202020;
    }

    &:active {
      border-color: var(--color-brand-secondary-shade);
      color: #202020;
    }

    &:disabled {
      color: #1C1B1F38;
      border-color: rgba(31, 31, 31, 0.12);
    }
  }
}

.priceMain {
  display: flex;
  justify-content: space-evenly;
  gap: 10px;
  padding: 16px;
  border-top: 2px solid #F7F7F7;

  @media (max-width: 768px) {
    justify-content: space-between;
    padding: 8px 0;
  }
}

.line {
  width: 2px;
  height: 52.01px;
  background: #F7F7F7;
}

.priceContainer {
  display: inline-flex;
  flex-direction: column;
  gap: 8px;

  @media (max-width: 1280px) {
    white-space: nowrap;
  }
}

.priceContainerLoad {
  flex: 1;

  &>* {
    width: 100%;
    margin: 0;
  }
}

.placeholder__Btn {
  height: 32px;
}

.oldPriceContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;

  @media(max-width: 880px) {
    font-size: 10px;
    white-space: nowrap;
  }
}

.oldPrice {
  font-size: 13px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-decoration-line: line-through;
  color: var(--color-location);
}

.oldPriceProcent {
  padding: 3px 5px;
  border-radius: 4px;
  background: var(--color-labels-red);
  color: var(--color-neutrals-white);
  margin-right: 3px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.currentPrice {
  display: inline-flex;
  align-items: center;
  flex: 1;
  margin-top: auto;

  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 100% */

  color: var(--color-neutrals-black, #000);
}

.bonusesContent {
  flex: 1;
  display: inline-flex;
  flex-direction: column;
  gap: 8px;

  font-size: 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;

  @media (max-width: 768px) {
    flex: none;
  }
}

.bonusTitle {
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
}

.bonusContainer {
  display: flex;
  align-items: center;
  gap: 5px;


  @media (max-width: 1280px) {
    white-space: nowrap;
  }
}

.bonuses {
  font-size: 14px;
  font-weight: 700;
}

.bonusIcon {
  width: 29px;
  height: 28px;
  flex-shrink: 0;
}

.infoIcon {
  width: 20px;
  height: 20px;
  fill: var(--color-brand-secondary-shade, #FB791B);
}

.blueBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  width: 100%;
  margin: 16px 0;

  color: var(--color-neutrals-white);
  background-color: var(--color-brand-primary);
  fill: var(--color-neutrals-white);

  &:hover {
    background-color: var(--color-brand-primary-shade);
  }

  &:active {
    background-color: var(--color-brand-primary-shade);
  }

  &[disabled] {
    opacity: .4;
    cursor: default;
    pointer-events: none;
  }

  @media (max-width: 768px) {
    height: 47px;
    font-size: 18px;

    svg {
      max-height: 24px;
      max-width: 24px;
    }
  }
}

[data-channel="samsung"] .blueBtn{
  background-color: var(--color-brand-primary-shade) !important;
  &:hover{
    background-color: var(--color-brand-primary) !important;
  }
  &:active {
    background-color: var(--color-brand-primary) !important;
  }
}

.textWithIcon {
  border: none;
  background-color: inherit;
  margin-top: 16px;

  &:hover {
    span {
      color: var(--color-brand-secondary-shade, #FB791B);
    }

    svg>g>path {
      fill: var(--color-brand-secondary-shade, #FB791B);
    }
  }
}

.text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.textBlue {
  color: var(--color-brand-primary-shade);
}

.iconRight {
  g>path {
    fill: var(--color-brand-primary-shade);
  }
}

.giftModal {
  display: flex;
  flex-direction: column;
  gap: 16px;

  min-width: 420px;
  max-width: 1380px;
  max-height: 95vh;

  padding: 32px;
  overflow-y: auto;

  &__container {
    background-color: var(--color-upper-header, #f7f7f7);
  }

  &__head {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
  }

  &__title {
    color: #000;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.22px;
  }

  @media (max-width: 768px) {
    gap: 16px;
    max-width: 80vw;
    padding: 32px;
  }

  @media (max-width: 640px) {
    gap: 4px;
    max-width: 80vw;
    min-width: calc(280px - 32px);
    padding: 16px;
  }
}

.closeBtn {
  display: inline-flex;
  width: 44px;
  height: 44px;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: inherit;
  border-radius: 100%;
  margin-right: -12px;

  &:hover {
    color: #202020;
    background: hsla(0, 0%, 60%, .1);
  }
}

.gifts {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 12px;

  &~div {
    display: flex;
    justify-content: center;
    gap: 12px;

    &>button {
      flex: 1;
      max-width: 200px;
    }
  }
}

.gifts__block {
  margin-bottom: 20px;
}

.gifts__block_title {
  font-size: 1.5rem;
  color: #333;
}

.gifts__items {
  display: flex;
  flex-wrap: wrap;
}

.banner_bonus {
  max-width: 100%;
  border-radius: 8px;
  margin-bottom: 8px;
}
