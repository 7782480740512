.title {
  font-weight: 800;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
  margin-bottom: 23px;
  position: relative;

  svg {
    position: absolute;
    right: 0;
  }
}

.downloadAppTxt {
  font-size: 14px;
  margin-top: 16px;
  color: #999999;
}

.qrWrap {
  -webkit-box-shadow: 0 0 6px 0px rgba(0,0,0,0.20);
  -moz-box-shadow: 0 0 6px 0px rgba(0,0,0,0.20);
  box-shadow: 0 0 6px 0px rgba(0,0,0,0.20);
  padding: 4px;
  background-color: #FFF;
  overflow: hidden;
  width: 100%;
  max-width: 240px;
  
  img {
    display: block;
    width: 100%;
    height: auto;
    max-width: 220px;
  }
}

.downloadAppMobile {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  margin: 6vw auto;

  img {
    height: 50px;
    width: auto;
  }

  @media (max-width: 640px) {
    justify-content: center;
    img {
      height: 12vw;
      width: auto;
    }
  }
}
