@import "global/styles/functions.scss";
$assetPath: "/assets/icons";

.wrapper {
  margin: 0 0 16px 20px;

  button {
    z-index: 0;

    &:hover {
      z-index: 0;
    }

    &:focus {
      z-index: 0;
    }
  }
}

.item {
  $this: &;
  position: relative;
  background: var(--color-upper-header);
  border-radius: 8px !important;

  &__icon {
    flex: 0 0 24px;
    height: 6px;
    margin-left: auto;
    fill: var(--color-brand-primary-shade, #029AAD);
    transition: .2s;
  }

  button {
    background: var(--color-upper-header);
    padding: 24px 24px 24px 44px;
    border-radius: 8px !important;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-neutrals-black);
    ;

    &::after {
      content: none;
    }

    &:focus {
      box-shadow: none;
    }

    &[aria-expanded=true] {
      background: var(--color-upper-header);
      color: var(--color-neutrals-black);
      border-radius: 8px 8px 0 0 !important;
      box-shadow: none;

      &::after {
        content: none;
      }

      #{$this}__icon {
        transform: scale(-1);
      }
    }
  }
}

.count {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  background: var(--color-brand-secondary);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-neutrals-white);
  z-index: 2;
}

.body {
  padding: 0 24px 24px 44px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--color-location-color);
}

.custom {
  width: 100%;
}

.customItem {
  $this: &;
  background: var(--color-neutrals-white);
  border: none !important;
  border-radius: 8px !important;

  &__icon {
    margin-left: auto;
    fill: var(--color-brand-primary-shade, #029AAD);
    transition: .2s;
    margin-right: 8px;
  }

  button {
    display: flex;
    align-items: center;
    background: transparent;
    padding: 18px 0;
    border: none;
    font-size: 18px;
    line-height: 18px;
    font-style: normal;
    font-weight: 700;
    color: var(--color-neutrals-dark-grey);

    &::after {
      content: none !important;
    }

    &:focus {
      border: none;
      box-shadow: none;
    }

    &[aria-expanded=true] {
      background: transparent;
      color: var(--color-neutrals-dark-grey);
      border: none;
      box-shadow: none;

      #{$this}__icon {
        transform: scale(-1);
      }
    }
  }
}

.customBody {
  padding: 0 24px 18px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(60, 60, 67, 0.85);
}

.footerBody {
  padding: 0 0 18px;
}

.footerItem {
  border-bottom: 1px solid #E3E2E7 !important;

  button {
    background: transparent;
    padding: 18px 0;
    border: none;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    color: var(--color-neutrals-black);

    @media (max-width: 640px) {
      padding: 4.8vw 0;
      font-size: 4.8vw;
      line-height: 4.8vw;
    }

    &::after {
      background-image: url(#{$assetPath}/CurveDown.svg);
      background-size: contain;
      width: 16px;
      height: 16px;

      @media (max-width: 640px) {
        width: 4.27vw;
        height: 4.27vw;
      }
    }

    &:focus {
      border: none;
      box-shadow: none;
    }

    &[aria-expanded=true] {
      background: transparent;
      color: var(--color-neutrals-black);
      border: none;
      box-shadow: none;

      &::after {
        background-image: url(#{$assetPath}/CurveDown.svg);
        transform: rotate(180deg);
      }
    }
  }
}

.filterItem {
  $this: &;
  background: var(--color-neutrals-white);
  border: none !important;
  border-radius: 8px !important;

  &__icon {
    flex: 0 0 24px;
    height: 6px;
    margin-left: auto;
    fill: var(--color-brand-primary-shade, #029AAD);
    transition: .2s;
  }

  button {
    background: transparent;
    padding: 16px 0 0;
    border: none;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: var(--color-neutrals-black);

    &::after {
      content: none;
    }

    &:focus {
      border: none;
      box-shadow: none;
    }

    &[aria-expanded=true] {
      background: transparent;
      color: var(--color-neutrals-black);
      border: none;
      box-shadow: none;

      #{$this}__icon {
        transform: scale(-1);
      }
    }
  }
}

.filterBody {
  padding: 0;
  margin-top: 16px;
}

.compareItem {
  $this: &;
  background: none;
  border: none !important;
  border-radius: 8px !important;

  &__icon {
    // flex: 0 0 24px;
    height: 6px;
    margin-left: 8px;
    fill: var(--color-brand-primary-shade, #029AAD);
    transition: .2s;
  }

  button {
    background: transparent;
    padding: 0;
    border: none;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: var(--color-neutrals-black);
    margin-bottom: 24px;

    &::after {
      content: none;
    }

    &:focus {
      border: none;
      box-shadow: none;
    }

    &[aria-expanded=true] {
      background: transparent;
      color: var(--color-neutrals-black);
      border: none;
      box-shadow: none;

      #{$this}__icon {
        transform: scale(-1);
      }
    }

    @media (max-width: 768px) {
      font-size: 4.8vw;
      line-height: 4.8vw;
      margin-bottom: 4.27vw;
    }
  }
}

.compareBody {
  padding: 0 0 24px;

  @media (max-width: 1280px) {
    padding: 0 0 getVwD(24px);
  }

  @media (max-width: 768px) {
    padding: 0 0 4.27vw;
  }
}

.questionItem {
  $this: &;
  background: none;
  padding: 11px 16px;
  border: none !important;
  border-radius: 20px !important;
  background: #FFF;

  &__icon {
    height: 24px;
    transition: .2s;
    fill: var(--color-brand-primary-shade, #029AAD);
  }

  &__round {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    min-height: 40px;
    margin-left: auto;

    border-radius: 100%;
    background: rgba(0, 188, 204, 0.05);
  }

  button {
    background: transparent;
    padding: 0;
    border: none;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: var(--color-neutrals-black);

    &::after {
      content: none;
    }

    &:focus {
      border: none;
      box-shadow: none;
    }

    &[aria-expanded=true] {
      background: transparent;
      color: var(--color-neutrals-black);
      border: none;
      box-shadow: none;

      #{$this}__icon {
        transform: scale(-1);
      }
    }

  }

  @media (max-width: 768px) {
    border-radius: 8px !important;

    button {
      font-size: 14px;
    }
  }
}

.questionBody {
  padding: 24px 0;
}

.orderItem {
  $this: &;
  border-radius: 8px !important;
  border: 1px solid #E3E2E7;

  &__icon {
    flex: 0 0 24px;
    max-width: 16px;
    height: 9px;
    margin-left: auto;
    transition: .2s;

    >path {
      fill: var(--color-location);
    }
  }

  button {
    background: transparent;
    padding: 8px 24px;
    border: none;
    gap: 24px;

    @media (max-width: 768px) {
      padding: 16px 10px;
      gap: 16px;
    }

    &::after {
      content: none;
    }

    &:focus {
      border: none;
      box-shadow: none;
    }

    &[aria-expanded=true] {
      background: transparent;
      box-shadow: none;

      #{$this}__icon {
        transform: scale(-1);
      }
    }
  }
}

.orderBody {
  padding: 0;
}

.active {
  border-color: var(--color-brand-secondary);
}

.adaptive {
  $this: &;

  button {
    padding: 0 !important;
    border: none;
    background: transparent;

    &::after {
      content: none;
    }

    &:focus {
      border: none;
      box-shadow: none;
    }

    &[aria-expanded=true] {
      background: transparent;
      box-shadow: none;
    }
  }
}

.orderBody {
  padding: 0;
}
