.showcase {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: 24px;
  padding: 2px 6px;
  border: 1px solid var(--color-Tuna);
  border-radius: 4px;

  @media(max-width: 768px) {
    height: 20px;
    padding: 2px 4px;
  }

  &__icon {
    width: 16px;
    height: 16px;

    @media(max-width: 768px) {
      width: 14px;
      height: 14px;
    }
  }

  &__text {
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;

    @media(max-width: 768px) {
      font-size: 14px;
    }
  }
}
