@import "global/styles/functions.scss";
$stickyTop: 90px;

.priceContent {
  position: sticky;
  top: $stickyTop;
  z-index: 2;

  border-radius: 8px;
  background: var(--color-neutrals-white, #FFF);
  padding: 32px 24px;

  &__web {
    top: 0;
  }

  @media (max-width: 1280px) {
    top: 70px;
    padding: 26px 18px;
  }
}

.priceHead {
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--color-upper-header, #F7F7F7);
}

.priceGuarantee {
  color: var(--color-brand-primary-shade, #029AAD);
  /* Web 1440px/B4 */
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration: none;
}

.iconContainer {
  position: relative;
  display: flex;
  align-items: flex-end;
  gap: 12px;

  svg {
    width: 20px;
    height: 18.394px;
    flex-shrink: 0;
    fill: var(--color-neutrals-border-solid, #E0E0E0);
    cursor: pointer;
    transition: .2s;

    &:hover {
      fill: var(--color-brand-primary);
      >path {
        fill: var(--color-brand-primary);
      }
    }

    &.activeBtn {
      fill: var(--color-brand-primary);

      &__fav {
        >path {
          fill: var(--color-brand-primary);
        }
      }
    }
  }

  @media (max-width: 1150px) {
    position: absolute;
    top: 0px;
    right: 0;
    flex-direction: column;
    z-index: 3;
  }
}

.priceMain {
  display: flex;
  justify-content: space-evenly;
  gap: 10px;
  margin-top: 16px;
}

.line {
  width: 2px;
  height: 52.01px;
  background: #F7F7F7;
}

.priceContainer {
  // flex: 1;
  display: inline-flex;
  flex-direction: column;
  gap: 8px;

  @media (max-width: 1280px) {
    white-space: nowrap;
  }
}

.priceContainerLoad {
  width: 80%;
}

.placeholder {
  margin: 0;

  &__cost {
    margin: 0;
    height: 24px;
  }

  &__Left {
    text-align: right;
  }

  &__Title {
    height: 24px;
  }

  &__Btn {
    margin-top: 12px;
    height: 48px;
  }

  &__delivery {
    display: inline-block;
    width: 90px;

    &>span {
      height: 24px;
    }
  }
}

.oldPriceContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;

  @media(max-width: 880px) {
    font-size: 10px;
    white-space: nowrap;
  }
}

.oldPrice {
  font-size: 15px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-decoration-line: line-through;
  color: var(--color-location);
}

.oldPriceProcent {
  padding: 3px 5px;
  border-radius: 4px;
  background: var(--color-labels-red);
  color: var(--color-neutrals-white);
  margin-right: 3px;
}

.currentPrice {
  margin-top: auto;
  font-size: 28px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;

  color: var(--color-neutrals-black, #000);

  @media(max-width: 880px) {
    font-size: 16px;
  }
}

.bonusesContent {
  flex: 1;
  display: inline-flex;
  flex-direction: column;
  gap: 8px;

  font-size: 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
}

.bonusTitle {
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
}

.bonusContainer {
  display: flex;
  align-items: center;
  gap: 5px;


  @media (max-width: 1280px) {
    white-space: nowrap;
  }
}

.bonuses {
  font-size: 14px;
  font-weight: 700;
}

.bonusIcon {
  width: 29px;
  height: 28px;
  flex-shrink: 0;
}

.infoIcon {
  width: 20px;
  height: 20px;
  fill: var(--color-brand-secondary-shade, #FB791B);
}

.blueBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  width: 100%;
  margin-top: 16px;

  color: var(--color-neutrals-white);
  background-color: var(--color-brand-primary);
  fill: var(--color-neutrals-white);

  &:hover {
    background-color: var(--color-brand-primary-shade);
  }

  &:active {
    background-color: var(--color-brand-primary-shade);
  }

  &[disabled] {
    opacity: .4;
    cursor: default;
    pointer-events: none;
  }
}

[data-channel="samsung"] .blueBtn{
  background-color: var(--color-brand-primary-shade) !important;
  &:hover{
    background-color: var(--color-brand-primary) !important;
  }
  &:active {
    background-color: var(--color-brand-primary) !important;
  }
}

.orangeBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  width: 100%;

  color: var(--color-neutrals-white);
  background-color: var(--color-brand-secondary);
  fill: var(--color-neutrals-white);

  &:hover {
    background-color: var(--color-brand-secondary-shade);
  }

  &:active {
    background-color: var(--color-brand-secondary-shade)
  }

  &[disabled] {
    opacity: .4;
    cursor: default;
    pointer-events: none;
  }

  @media (max-width: 640px) {
    height: 48px;
    font-size: 18px;
  }
}

.creditAndInstallment {
  display: flex;
  flex-direction: column;
  gap: 10px;

  margin-top: 8px;
  padding: 16px;
  border-radius: 8px;

  background: var(--color-upper-header, #F7F7F7);
}

.creditAndInstallmentContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--color-neutrals-border-solid);

  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
}

.creditAndInstallmentTitle {
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}

.creditAndInstallmentInfo {
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.creditAndInstallmentPrice {
  font-weight: 700;
  margin-right: 4px;
}

.deliveryMethods {
  position: sticky;
  top: $stickyTop;
  z-index: 1;

  display: flex;
  flex-direction: column;
  gap: 16px;

  border-radius: 8px;
  background: var(--color-neutrals-white, #FFF);
  padding: 32px 24px;

  @media (max-width: 1080px) {
    position: static;
    padding: 32px 16px;
    border-radius: 0;
  }

  @media (max-width: 768px) {
    margin: 0 -10px;
  }
}

.deliveryMethod {
  display: flex;
  align-items: flex-start;
  gap: 8px;

  color: var(--color-Tuna, #3C3C43);
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.28px;


  @media (max-width: 1280px) {
    white-space: nowrap;
  }
}

.deliveryTitle {
  font-weight: 700;
  line-height: 18px;
}

.delivery {
  margin-right: auto;
}

.deliveryTooltips {
  position: relative;
  display: inline-block;
  margin-left: 4px;
  cursor: pointer;

  &>svg {
    fill: #DDE0E5;
    &:hover{
      fill: var(--color-brand-primary-shade);
    }
  }

}

.questionTooltip {
  padding: 7px 14px;
  border-radius: 8px;
  background: var(--color-neutrals-grey, #F2F2F2);

  box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.10), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);

  color: var(--color-Emperor, #4F4F4F);

  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;

  z-index: 1;
  /* 125% */

  &__content {
    position: relative;
    background: inherit;

    &::before {
      content: '';
      position: absolute;
      top: -18px;
      left: 50%;

      width: 18px;
      height: 18px;
      background: var(--color-neutrals-grey, #F2F2F2);
      transform: rotate(45deg);
      z-index: -1;
    }
  }
}

.deliveryBtn {
  display: inline-block;
  width: auto;
  padding: 0;
  margin-top: 6px;
  border: 0;
  border-bottom: 1px dashed var(--color-brand-primary, #00BCCC);
  outline: none;

  background-color: inherit;
  color: var(--color-brand-primary, #00BCCC);

  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.28px;
}

.productPhoto {
  display: flex;
  align-items: center;
  gap: 4px;
  max-height: 0;
  overflow: hidden;
  border-bottom: 1px solid var(--color-upper-header, #F7F7F7);
  transition: max-height .3s, margin .3s, padding .3s;

  &_Active {
    max-height: 200px;
    margin: 16px 0 0 0;
    padding-bottom: 16px;
  }

  &_Img {
    width: 52px;
    height: 52px;
    flex-shrink: 0;
  }

  &_Text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    font-size: 16px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: var(--color-neutrals-black, #000);
  }
}

.item {
  color: var(--color-neutrals-black, #000);
  padding: 4px 12px;
  /* Web 1440px/B6 */
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.28px;

  &>svg {
    margin-right: 8px;
    fill: #C4C4C4;
  }
}

.deliveryFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 0;
  margin-top: 0;

  color: var(--evrika-border-solid-2, #999);
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  transition: max-height .4s, margin-top .3s;
  overflow: hidden;

  &.active {
    max-height: 99px;
    margin-top: 16px;
  }

  &_content {
    &>span {
      color: var(--color-brand-primary, #00BCCC);
      font-weight: 300;
    }

    &>svg {
      height: 17px;
      fill: var(--color-brand-primary, #00BCCC);
    }
  }
}

.deliveryMap {
  display: flex;
  flex-direction: column;

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 32px;
    color: #000;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.22px;
  }

  &__body {
    width: 50vw;
    height: 80vh;

    @media only screen and (max-width: 767px) {
      /* Apply styles for screens with a maximum width of 767px (typical mobile devices) */
      width: 100%;
    }
  }
}

.closeBtn {
  padding: 0;
}

.socialPopapWidget {
  $this: &;
  position: relative;

  &__content {
    background: #fff;
    box-shadow: 0 0 16px rgba(0, 0, 0, .15);
    position: absolute;
    border-radius: 12px;
    bottom: auto;
    top: calc(100% + 0.4375rem);
    right: -20px;
    opacity: 0;
    visibility: hidden;
    transition: opacity .2s ease, visibility .2s ease;
    padding: 14px 12px 10px;
    min-width: 270px;

    &::after {
      content: "";
      background: inherit;
      position: absolute;
      transform: rotate(-45deg);
      height: 12px;
      width: 12px;
      bottom: auto;
      top: -0.3125rem;
      right: 1.5625rem;
      margin: 0 auto;
    }
  }

  &:hover {
    #{$this}__content {
      opacity: 1;
      visibility: visible;
    }

    &>svg {
      fill: var(--color-brand-primary);
    }
  }
}

.calculateCreditWrap {
  &.hide {
    visibility: hidden;
    width: 0px;
    height: 0px;

    .partnersBtn,
    .halykBtn,
    .kaspiWidget {
      transition: all 400ms ease-in-out;
      opacity: 0;
      transform: translateY(-24px);
    }
  }

  display: flex;
  flex-direction: column;
  gap: 12px;

  .partnersBtn {
    display: flex;
    visibility: visible;
    align-items: center;
    justify-content: space-between;
    line-height: 1;
    gap: 8px;
    width: 100%;
    color: var(--color-neutrals-white);
    background-color: var(--color-brand-secondary);
    position: relative;
    padding: 8px 16px;
    transition: all 400ms ease-in-out;
    opacity: 1;
    transform: translateY(0);
    transition-delay: 400ms;

    &:hover,
    &:active {
      background-color: var(--color-brand-secondary-shade);
    }

    &[disabled] {
      opacity: .4;
      cursor: default;
      pointer-events: none;
    }

    svg {
      width: 36px;
      height: 36px;
      fill: #FFF;

      @media (max-width: 768px) {
        width: 8vw;
        height: 8vw;
      }
    }

    .partnersTxtAndLogo {
      width: 100%;

      &>span {
        font-size: 12px;
        display: block;
        margin-bottom: 4px;
      }

      div {
        font-size: 16px;

        &>span {
          font-size: 12px;
        }
      }

      @media (max-width: 1280px) {
        &>span {
          font-size: getVwD(12px);
          margin-bottom: getVwD(4px);
        }

        div {
          font-size: getVwD(16px);

          &>span {
            font-size: getVwD(12px);
          }
        }
      }

      @media (max-width: 768px) {
        &>span {
          font-size: getVwM(12px);
          margin-bottom: getVwM(4px);
        }

        div {
          font-size: getVwM(16px);

          &>span {
            font-size: getVwM(12px);
          }
        }
      }
    }

    .curveRight {
      display: block;
      width: 24px;
      height: auto;
      fill: var(--color-neutrals-white);
    }

    @media (max-width: 768px) {
      padding: 2vw 3vw;

      .curveRight {
        width: 8vw;
        height: 8vw;
      }
    }
  }

  .halykBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1;
    gap: 8px;
    width: 100%;
    color: #FFF;
    background-color: #08B35E;
    position: relative;
    padding: 6px 16px;
    transition: all 400ms ease-in-out;
    opacity: 1;
    transform: translateY(0);
    transition-delay: 200ms;

    &:hover {
      background-color: #16945D;
      color: #FFF;
    }

    &:active {
      background-color: #16945D;
      color: #FFF;
    }

    &[disabled] {
      opacity: .4;
      cursor: default;
      pointer-events: none;
    }

    svg {
      width: 36px;
      height: 36px;

      @media (max-width: 768px) {
        width: 8vw;
        height: 8vw;
      }
    }

    .partnersTxtAndLogo {
      width: 100%;

      &>span {
        font-size: 12px;
        display: block;
        margin-bottom: 4px;
      }

      div {
        font-size: 16px;

        &>span {
          font-size: 12px;
        }
      }

      @media (max-width: 1280px) {
        &>span {
          font-size: getVwD(12px);
          margin-bottom: getVwD(4px);
        }

        div {
          font-size: getVwD(16px);

          &>span {
            font-size: getVwD(12px);
          }
        }
      }

      @media (max-width: 768px) {
        &>span {
          font-size: getVwM(12px);
          margin-bottom: getVwM(4px);
        }

        div {
          font-size: getVwM(16px);

          &>span {
            font-size: getVwM(12px);
          }
        }
      }
    }

    .curveRight {
      display: block;
      width: 24px;
      height: auto;
      fill: #FFF;
    }

    @media (max-width: 768px) {
      padding: 2vw 3vw;

      .curveRight {
        width: 8vw;
        height: 8vw;
      }
    }
  }

  .kaspiWidget {
    background-color: #f14635;
    width: 100%;
    overflow: hidden;
    border-radius: 8px;
    position: relative;
    transition: all 400ms ease-in-out;
    opacity: 1;
    transform: translateY(0);

    &:after {
      display: block;
      width: 23px;
      height: 26px;
      content: '';
      position: absolute;
      top: 12px;
      right: 14px;
      background-image: url(/assets/kaspi-right.png);
    }

    iframe {
      display: block;
      width: calc(100% + 112px) !important;
      transform: scale(1.1);
      margin-left: -112px;

      @media (max-width: 1400px) {
        margin-left: -7vw;
        width: calc(100% + 8vw) !important;
      }

      @media (max-width: 640px) {
        margin-left: -15vw;
        width: calc(100% + 15vw) !important;
      }
    }
  }

}

.OutOfStockBlock {
  span {
    display: block;
    margin: 16px 0 8px 0;
    font-size: 14px;
    color: var(--evrika-border-solid-2, #999);
  }
}

.basket {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 100%;
  height: 40px;
  padding: 4px 16px;

  border-radius: 10px;
  background: var(--color-neutrals-white);

  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  color: var(--color-brand-primary);
  text-decoration: none;
  border: 1px solid var(--color-brand-primary);

  @media(max-width: 640px) {
    width: 100%;
    height: 8.8vw;
    padding: 4px 8px;

    font-size: 2.8vw;
    font-weight: 700;
    line-height: normal;
  }
}

.accoridionMethods {
  $this: &;
  border-radius: 8px;
  border: none;

  &_Arrow {
    width: 20px;
    height: 20px;
    transition: transform .5s;

    margin-left: auto;
    fill: var(--color-brand-primary-shade);

    @media (max-width: 768px) {
      width: 12px;
      height: auto;
    }
  }

  &_ArrowActive {
    transform: scale(-1);
  }

  &_Body {
    padding: 5px 10px;
  }

  button {
    background: transparent;
    border: none;
    gap: 8px;
    padding: 16px 23px;
    font-weight: 700;
    line-height: 18px;
    font-size: 14px;
    z-index: 1 !important;
    color: var(--color-neutrals-black) !important;

    @media (max-width: 768px) {
      padding: 16px 10px;
      gap: 16px;
    }

    &::after {
      content: none;
    }

    &:focus {
      border: none;
      box-shadow: none;
    }

    &[aria-expanded=true] {
      background: transparent;
      box-shadow: none;

      #{$this}__icon {
        transform: scale(-1);
      }
    }
  }
}
