.specifications {
  display: flex;
  flex-direction: column;

  &_Title {
    color: var(--color-Woodsmoke, #0D0E0F);
    font-size: 22px;
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.22px;
    margin-top: 32px;
    margin-bottom: 24px;
  }
}

.specification {
  display: flex;
  padding: 32px 0;
  border-bottom: 1px solid var(--color-neutrals-border-solid, #E0E0E0);

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: none;
  }

  @media (max-width:768px) {
    border-bottom: none;
  }

  &_Name {
    width: 40%;

    color: var(--color-neutrals-black, #000);

    /* Web 1440px/B2 */
    font-size: 16px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &_Content {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 16px 0;

    &_Name,
    &_Content {
      width: 100%;
    }

    &_Name {
      margin-bottom: 16px;
    }
  }
}

.description {
  display: flex;
  flex-direction: column;
  gap: 32px;

  &_Title {
    margin-bottom: 16px;

    font-size: 16px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: var(--color-Tuna, #3C3C43);
  }

  &_Text {
    color: var(--color-Emperor, #4F4F4F);
    font-size: 14px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
  }

  @media (max-width: 768px) {
    margin-top: 16px;
    gap: 16px;
  }
}

.btn {
  width: fit-content;
  text-align: left;
  color: var(--color-brand-primary-shade, #029AAD);
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;

  padding: 0;
  margin: 32px 0 0 0;
  border: none;
  background: inherit;
}
