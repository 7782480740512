.link {
  font-weight: 400;
  font-size: 10px;
  line-height: 21px;
  letter-spacing: 0.05em;
  color: #FFFFFF;
  text-decoration: none;

  @media (max-width: 640px) {
    font-size: 2.67vw;
    line-height: 5.6vw;
  }
}

.hover {
  transition: color .2s ease;

  &:hover {
    color: var(--color-brand-secondary);
  }
}

.text {
  &:not(:first-child) {
    position: relative;
    padding-left: 1.1875rem;

    :before {
      background: hsla(0, 0%, 100%, .7);
      border-radius: 50%;
      bottom: 0;
      content: "";
      height: .25rem;
      left: .4375rem;
      margin: auto;
      position: absolute;
      top: .25rem;
      width: .25rem
    }
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 32px;
  padding-bottom: 32px;
}

.footerBottom {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  background: linear-gradient(180deg, #232526 0%, #414345 100%);
  padding: 4px 0;

  @media (max-width: 640px) {
    margin-bottom: 17.53vw;
    padding: 1vw;
  }
}

.iconWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.nav {
  display: flex;
  align-items: center;
}

.navWrapper {
  line-height: normal;
  margin: 0 24px;
  padding: 0 24px;
  border-left: 1px solid var(--color-neutrals-white);
  border-right: 1px solid var(--color-neutrals-white);
}
