.characteristic {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px dashed var(--color-silver, #3C3C43);
}

.characteristicKey {
  flex: 1 1 50%;
  color: var(--color-Tuna, #3C3C43);

  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
}

.characteristicContainer {
  flex: 1 1 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.characteristicValue {
  color: var(--color-neutrals-black, #000);
  text-align: right;

  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
}
