@import "global/styles/functions.scss";
$checkImage: "data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e";

.checkmark[type=radio] {
  border: 1px solid var(--color-checkmark);

  &:checked {
    background-image: url($checkImage);
  }

  &:hover {
    border: none;
    background-color: var(--color-brand-secondary);
    background-image: url($checkImage);
  }
}

.square {
  border-radius: 4px;

  input {
    &:disabled {
      border: none;
    }
  }
}

.label {
  position: relative;
  width: 204px;
  height: 158px;

  &>input:checked~span {
    border-color: var(--color-brand-primary-shade);
  }
}

.labelDisabled {
  width: 204px;
  height: 158px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  border: 1px solid var(--color-neutrals-border-solid);
  border-radius: 8px;
  color: var(--color-location);

  // font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;

  &>img {
    filter: contrast(0);
  }
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  border: 1px solid var(--color-neutrals-border-solid);
  border-radius: 8px;
  cursor: pointer;

  // font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;

  &:hover {
    border: 1px solid var(--color-brand-secondary);
  }
}

input.hidden {
  visibility: hidden;
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: var(--color-brand-secondary);

  background-image: url($checkImage);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  appearance: none;

  &:checked {
    visibility: visible;
  }
}

.labelRadioBtn {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  color: var(--color-neutrals-black);
  cursor: pointer;

  @media (max-width: 1280px) {
    font-size: getVwD(16px);
  }

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: normal;
  }

  @media (max-width: 640px) {
    font-size: 3.73vw;
  }
}

// .radioBtn {
//   @media (max-width: 1280px) {
//     height: getVwD(16px);
//     width: getVwD(16px);
//   }

//   @media (max-width: 768px) {
//     height: getVwM(24px);
//     width: getVwM(24px);
//   }
// }
