.container {
  width: 120px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  svg {
    width: 100%;
    height: 100%;
    fill: #C4C4C4;
    stroke: #C4C4C4;
  }

  &>label {
    cursor: pointer;
  }

  &>input:checked~label,
  &:not(:checked)>label:hover,
  &:not(:checked)>label:hover~label {
    svg {
      fill: var(--color-brand-secondary-shade);;
      stroke: var(--color-brand-secondary-shade);
    }
  }
}

.disabledContainer {
  width: 120px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  svg {
    width: 100%;
    height: 100%;
    fill: #C4C4C4;
    stroke: #C4C4C4;
  }

  &>input:checked~label {
    svg {
      fill: var(--color-brand-secondary-shade);
      stroke: var(--color-brand-secondary-shade);
    }
  }
}

.input {
  width: 0;
  height: 0;
  opacity: 0;
}

.star {
  position: relative;
  width: 20px;
  height: 19px;

  &>svg {
    position: absolute;
    top: 0;
  }
}
