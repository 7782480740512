.availability {
  margin-top: 32px;

  &_Head {
    position: relative;
    display: flex;
    justify-content: space-between;
  }

  &_Title {
    color: var(--color-Woodsmoke, #0D0E0F);
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.22px;
  }

  &_Select {
    cursor: pointer;
    user-select: none;

    >svg {
      margin-left: 12px;
      transform: rotate(90deg);
      transition: transform .2s;
    }

    &.active {
      >svg {
        transform: rotate(90deg) scale(-1);
      }
    }

    &:hover {
      color: var(--color-brand-secondary-shade);
    }
  }

  &_Container {
    display: none;
    position: absolute;
    top: 50px;
    right: -20px;
    width: 270px;
    max-height: 320px;
    background-color: white;
    color: var(--color-neutrals-black);
    box-shadow: 0 4px 20px rgba(0, 0, 0, .12);

    &.active {
      display: block;
      overflow-x: scroll;
      z-index: 2;
    }
  }

  &_Option {
    padding: 12px 16px;
    cursor: pointer;

    &:hover {
      background: #f2f2f3;
    }
  }
}

.table {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &_String {
    display: flex;
    // align-items: center;
    gap: 28px;
    color: var(--color-Dark-Tuna, #393948);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 8px;
    }
  }

  &_Head {
    color: var(--color-French-Gray, #C3C2CA);
    font-size: 14px;

    &>div {
      white-space: nowrap;
    }
  }

  &_Column {
    display: flex;

    @media (min-width: 768px) {
      flex-direction: column;

      &:nth-child(1) {
        width: 28.2%;
      }

      &:nth-child(2) {
        width: 20.8%;
      }

      &:nth-child(3) {
        width: 16%;
      }

      &:nth-child(4) {
        width: 12%;
      }

      &:nth-child(5) {
        width: 11%;
      }

      &:nth-child(6) {
        width: 8.5%;
      }
    }

    @media (max-width: 768px) {
      justify-content: space-between;
    }
  }

  &_BotTitle {
    color: var(--color-Dark-Tuna, #393948);
    font-size: 12px;
  }

  &_Phone {
    color: var(--color-brand-primary-shade, #029AAD);
    text-decoration: none;

    @media (max-width: 768px) {
      color: var(--txt-gray-text, #4F4F4F);
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 15px;
    }
  }

  @media (max-width: 768px) {
    &_TopTitle {
      max-width: 60%;
      color: var(--color-Emperor, #4F4F4F);
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
    }

    &_WorkTime {
      color: var(--color-Emperor, #4F4F4F);
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 15px;
    }

    &_ColumnPlace {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &_ColumnMap {
      display: contents;

      &>svg {
        min-width: 20px;
        margin: 0;
      }
    }

    &_ColumnAddress {
      color: var(--txt-gray-text, #4F4F4F);
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 15px;
    }
  }
}

.available {
  font-size: 13px;
}

.geographyIcon {
  margin-right: auto;
  margin-left: auto;
  cursor: pointer;
}

.placeholder {
  height: 36px;
  margin-bottom: 12px;

  :last-child {
    margin-bottom: 0;
  }
}

.mapContainer {
  max-height: 0;
  overflow: hidden;

  &.active {
    max-height: 9999px;
  }
}

.noAvailable {
  margin-left: 30%;
}
