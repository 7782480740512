.authContainer {
  width: 420px;
  max-height: 95vh;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px;
  overflow-y: auto;

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
  }

  &__head {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    @media (max-width: 768px) {
      justify-content: center;
    }
  }

  &__arrow {
    display: none;

    @media (max-width: 768px) {
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__title {
    color: #000;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.22px;
    margin-bottom: 0;

    @media (max-width: 640px) {
      font-size: 4.8vw;
    } 
  }

  &__text {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    text-align: center;
    margin-bottom: 16px;
    
    @media (max-width: 640px) {
      font-size: 3.2vw;
      line-height: 3.73vw;
    } 
  }

  @media (max-width: 768px) {
    gap: 16px;
    width: 80vw;
    padding: 32px;
  }

  @media (max-width: 640px) {
    gap: 4px;
    width: 80vw;
    min-width: calc(280px - 32px);
    padding: 16px;
  }
}

.closeBtn {
  display: inline-flex;
  width: 44px;
  height: 27px;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: inherit;
  border-radius: 100%;
  margin-right: -12px;

  &:hover {
    color: #202020;
    background: hsla(0, 0%, 60%, .1);
  }

  @media (max-width: 768px) {
    display: none;
  }
}

.auth {
  &__blueText {
    border: none;

    background-color: inherit;
    color: var(--color-brand-primary-shade, #029AAD);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;

    &:hover {
      color: var(--color-brand-secondary);
    }
  }

  &__register {
    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--color-River-Bed, #4B5563);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

    &>button {
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }

  @media (max-width: 640px) {
    &__register {
      font-size: 3.2vw;

      &>button {
        font-size: 3.2vw;
      }
    }
  }
}

.orangeBtn {
  width: 100%;
  background-color: var(--color-brand-secondary);
  color: var(--color-neutrals-white);
  margin: 24px 0 16px;

  &:hover {
    background-color: var(--color-brand-secondary-shade);
  }

  @media (max-width: 640px) {
    margin: 16px 0;
  }
}

[data-channel="samsung"] .orangeBtn{
  background-color: var(--color-brand-primary-shade);
  &:hover{
    background-color: var(--color-brand-primary);
  }
}
.errorText {
  display: block;
  color: red;
  font-size: 11px;
  line-height: 1;
  margin-top: 4px;
  text-align: left;
  width: 100%;
}

.errorInput {
  background: url("data:image/svg+xml;utf8,<svg fill='rgb(248, 65, 71)' width='16' height='14' viewBox='0 0 14.56 12.98' xmlns='http://www.w3.org/2000/svg'><path d='m14.42 11.49-6.27-10.99a1 1 0 0 0 -1.74 0l-6.28 11a1 1 0 0 0 .87 1.5h12.56a1 1 0 0 0 .86-1.51zm-6.14-.49a1 1 0 1 1 -2 0v-1a1 1 0 1 1 2 0zm0-4a1 1 0 1 1 -2 0v-3a1 1 0 0 1 2 0z'/></svg>") right 12px center no-repeat #fff;
  border-color: red !important;
  padding-right: 40px;
}

.linkWrapper {
  text-align: center;
  margin-bottom: 8px;
}

.linkText {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: var(--color-location);
  cursor: pointer;
}

.code_text {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 8px;

  @media (max-width: 640px) {
    font-size: 3.2vw;
  }
}
