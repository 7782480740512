.container {
  display: inline-flex;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: none;

  &:hover > .icon {
    filter: drop-shadow(0 2px 2px rgba(0,0,0,.25));
  }
}

.count {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -5px;
  right: -5px;
  width: 22px;
  height: 22px;
  background: var(--color-brand-secondary);
  border-radius: 30px;
  font-weight: 800;
  font-size: 11px;
  line-height: 10px;
  color: var(--color-neutrals-white);
}

.title {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: var(--color-neutrals-white);
  overflow: hidden;
  @media(max-width: 1200px) {
   display: none; 
  }
}

.link {
  display: flex;
  flex-flow: column;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
}

.wrapper {
  width: 32px;
  height: 32px;
}

.addBtn {
  animation: addAnimate .3s forwards;
}

.removeBtn {
  animation: removeAnimate .3s forwards;
}

@keyframes addAnimate {
  from {
    opacity: 0;
    max-height: 0;
    max-width: 0;
  }
  to {
    opacity: 1;
    max-height: 30px;
    max-width: 100px;
  }
}

@keyframes removeAnimate {
  from {
    opacity: 1;
    max-height: 30px;
    max-width: 100px;
  }

  to {
    opacity: 0;
    max-height: 0;
    max-width: 0;
  }
}

.img {
  width: 32px;
  height: 32px;
}

.shakeTop {
  width: 32px;
  height: 32px;
  animation: shake .82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.popup {
  display: none;
  color: var(--color-Emperor);
  background: var(--color-neutrals-white);
  box-shadow: 0 0 16px rgba(0, 0, 0, .15);
  position: absolute;
  border-radius: 8px;
  bottom: auto;
  top: 100%;
  transition: visibility .3s ease;
  transform: translateZ(0);
  right: 8px;
  padding: 16px 20px;
  width: 360px;
  z-index: 100;

  &::after {
    content: "";
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #fff;
    top: -8px;
    position: absolute;
    right: 6%;
    width: 0;
    height: 0;
  }
}
