.container {
  max-width: 848px;
  margin: auto;
  margin-top: 32px;

  &>div {
    box-sizing: border-box;
  }

  img {
    max-width: 100%;
  }

  @media (max-width: 1080px) {
    max-width: 90vw;
  }
}

.descr {
  position: relative;
  overflow: hidden;
  height: 104px;

  &::after {
    content: "";
    position: absolute;
    background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), #fff 70%, #fff);
    bottom: 0;
    left: 0;
    height: 24px;
    width: 100%;
    z-index: 2;
  }
}

.more {
  min-height: 104px;
}

.more_btn {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.1875rem;
  letter-spacing: -.02em;
  color: var(--color-brand-primary);
  border: none;
  background: 0 0;
  margin-top: 10px;
  padding: 0;
  cursor: pointer;

  &:hover {
    color: var(--color-brand-primary-shade);
  }

  @media (max-width: 768px) {
    width: max-content;
    font-size: 14px;
    line-height: 17px;
  }
}
