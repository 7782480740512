.authContainer {
  width: 420px;
  max-height: 95vh;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px;
  overflow-y: auto;

  &__head {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
      justify-content: center;
      flex: none;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    
    @media (max-width: 768px) {
      overflow-y: auto;
      height: 100vh;
      gap: 6.4vw;
      margin-bottom: 6.4vw;
    }
  }

  &__title {
    color: #000;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.22px;
    margin-bottom: 0;

    @media (max-width: 640px) {
      font-size: 4.8vw;
    }
  }

  &__arrow {
    display: none;

    @media (max-width: 768px) {
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  @media (max-width: 768px) {
    gap: 16px;
    width: 80vw;
    padding: 32px;
  }

  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    padding: 24px 16px;
    padding-bottom: 88px;
    background-color: var(--color-neutrals-white) !important;
    z-index: 10;
  }

  @media (max-width: 640px) {
    padding: 6.4vw 16px;
    gap: 6.4vw;
  }
}

.closeBtn {
  display: inline-flex;
  width: 44px;
  height: 27px;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: inherit;
  border-radius: 100%;
  margin-right: -12px;

  &:hover {
    color: #202020;
    background: hsla(0, 0%, 60%, .1);
  }

  @media (max-width: 768px) {
    display: none;
  }
}
