.catalogModal {
  $this: &;
  position: relative;

  min-width: 100vw;
  height: calc(100vh);
  overflow-y: auto;

  padding: 52px 16px;
  padding-bottom: 88px;

  background-color: #F7F7F7 !important;

  &__accordion {
    margin-top: 16px;
    border: none;

    button {
      color: var(--color-Mine-Shaft, #202020);

      font-size: 14px;
      font-style: normal;
      font-weight: 400;

      &[aria-expanded=true] {
        color: var(--color-brand-primary-shade, #029AAD);
        font-weight: 600;

        #{$this}__itemIcon {
          transform: scaleY(-1);
        }
      }
    }
  }

  &__accordionBody {
    padding-top: 0;
  }

  &__itemHead {
    display: flex;
    align-items: center;
    gap: 11px;
    width: 100%;
    margin: 16px;

    &>img {
      width: 24px;
      height: 24px;
    }
  }

  &__itemIcon {
    margin-left: auto;
    margin-right: 4px;
    fill: var(--color-neutrals-border-solid);
    transition: transform .2s;
  }
}

.contentLinks {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.linkItems {
  display: inline-block;
}

.link {
  $this: &;
  border: none;

  button {
    color: var(--color-brand-primary-shade, #029AAD);

    font-size: 14px;
    font-style: normal;
    font-weight: 400;

    &[aria-expanded=true] {
      font-weight: 400;

      #{$this}__icon {
        transform: scaleY(-1);
      }
    }
  }

  &__head {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;

    &>img {
      width: 24px;
      height: 24px;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0;
    margin-top: 8px;
  }

  &__icon {
    margin-left: auto;
    fill: var(--color-neutrals-border-solid);
    transition: transform .2s;
  }
}

.category {
  color: var(--color-Mine-Shaft, #202020);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.28px;

  text-decoration: none;
}
