.tabContainer {
  display: flex;
}

.Title {
  color: var(--color-Woodsmoke, #0D0E0F);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  /* 112.5% */

  @media (max-width: 768px) {
    color: var(--color-Woodsmoke, #0D0E0F);
  }
}
