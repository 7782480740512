.auth {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .auth &__loginNick {
    padding: 20px 13px 6px;

    &~label {
      left: 13px !important;
    }

    &:active~label,
    &:focus~label {
      left: 13px !important;
    }
  }

  &__remember {
    display: flex;
    align-items: center;
    justify-content: space-between;

    >div>input {
      cursor: pointer;
    }
  }

  &__rememberCheck {
    color: var(--color-Emperor, #4F4F4F);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
    cursor: pointer;

    @media (max-width: 640px) {
      font-size: 3.2vw;
    }
  }

  &__checkbox {
    @media (max-width: 640px) {
      padding-left: 5.53vw;

      input {
        width: 4.27vw;
        height: 4.27vw;
        margin-left: -5.53vw !important;
      }
    }
  }

  &__rememberLink {
    margin-left: 2px;
    text-decoration: none;
    cursor: pointer;
  }

  &__rememberLink,
  &__blueText {
    border: none;

    background-color: inherit;
    color: var(--color-brand-primary-shade);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;

    @media (max-width: 640px) {
      font-size: 3.2vw;
    }
  }

  &__register {
    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--color-River-Bed, #4B5563);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

    &>button {
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }

  @media (max-width: 640px) {
    gap: 8px;
    max-height: 70vh;

    &__remember {
      &>div {
        margin-right: 0;
      }
    }

    &__register {
      font-size: 12px;

      &>button {
        font-size: 12px;
      }
    }
  }
}

.orangeBtn {
  width: 100%;
  margin-top: 12px;
  color: var(--color-neutrals-white);
  background-color: var(--color-brand-secondary);

  &:hover {
    background-color: var(--color-brand-secondary-shade);
  }

  &:active {
    background-color: var(--color-brand-secondary-shade);
  }

  &:disabled {
    color: #202020;
    background-color: #FFC39C;
  }

  @media (max-width: 768px) {
    margin-top: 16px;
  }
}

[data-channel="samsung"] .orangeBtn{
  background-color: var(--color-brand-primary-shade);
  &:hover{
    background-color: var(--color-brand-primary);
  }
}

.errorText {
  display: block;
  color: red;
  font-size: 11px;
  line-height: 1;
  margin-top: 4px;
  text-align: left;
  width: 100%;
}

.errorInput {
  background: url("data:image/svg+xml;utf8,<svg fill='rgb(248, 65, 71)' width='16' height='14' viewBox='0 0 14.56 12.98' xmlns='http://www.w3.org/2000/svg'><path d='m14.42 11.49-6.27-10.99a1 1 0 0 0 -1.74 0l-6.28 11a1 1 0 0 0 .87 1.5h12.56a1 1 0 0 0 .86-1.51zm-6.14-.49a1 1 0 1 1 -2 0v-1a1 1 0 1 1 2 0zm0-4a1 1 0 1 1 -2 0v-3a1 1 0 0 1 2 0z'/></svg>") right 12px center no-repeat #fff;
  border-color: red !important;
  padding-right: 40px;

  &::-webkit-calendar-picker-indicator {
    opacity: 0;
  }
}

.linkWrapper {
  text-align: center;
  margin-top: 12px;
}

.linkText {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: var(--color-location);
  cursor: pointer;
}

.input {
  position: relative;
  width: 100%;
  height: 50px;
  padding: 10px 13px;
  border-radius: 8px;
  border: 1px solid var(--color-neutrals-border-solid);
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: var(--color-Mine-Shaft);

  &:focus-visible {
    outline: none;
  }

  @media (max-width: 1280px) {
    height: getVwD(50px);
    padding: getVwD(10px) getVwD(24px);
    font-size: getVwD(16px);
    line-height: getVwD(26px);
  }

  @media (max-width: 768px) {
    height: 13.3vw;
    padding: 4.27vw;
    font-size: 3.73vw;
    line-height: normal;
  }
}

.inputDate {
  &::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    width: 16px;
    height: 16px;
    border-width: thin;
  }
}

.checkboxWrapper {
  display: flex;
  gap: 8px;

  >div {
    >input {
      &[type=radio]:checked {
        background-color: var(--color-brand-secondary);
        border-color: var(--color-brand-secondary);
      }

      &[type=radio]:hover {
        border: 4px solid var(--color-brand-secondary);
      }
    }

    >label {
      font-weight: 600;
    }
  }
}

.code_text {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 8px;

  @media (max-width: 640px) {
    font-size: 3.2vw;
  }
}
