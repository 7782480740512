@import "global/styles/functions.scss";

.installment {
  display: flex;
  flex-direction: column;

  margin-top: 32px;

  &_Title {
    color: var(--color-Woodsmoke, #0D0E0F);
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.22px;
  }

  &_Description {
    color: var(--color-location, #999);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  &_Info {
    display: flex;
    gap: 10px;

    margin-top: 26px;
  }

  &_calc {
    display: flex;
    flex-direction: column;
    margin: 32px 0;
  }

  &_Steps {
    margin-top: 32px;
  }

  button {
    padding: 24px 24px 24px 44px;
  }

  @media (max-width: 768px) {
    margin-top: 8px;

    &_Title {
      font-size: getVwD(46px);
    }

    &_Description {
      font-size: getVwD(36px);
      line-height: normal;
    }

    &_Info {
      flex-wrap: wrap;
      gap: 8px;
      margin-top: 16px;
    }

    &_calc {
      margin: 12px 0;
    }

    &_Steps {
      margin-top: 10px;
    }
  }
}

.info {
  flex: 1 1 25%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 16px;

  border-radius: 8px;
  background: var(--evrika-background, #F7F7F7);

  &_Text {
    color: var(--color-neutrals-black, #000);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  @media (max-width: 768px) {
    flex: 1 1 48%;
    gap: 8px;
    padding: 10px;

    &_Text {
      font-size: getVwD(40px);
      line-height: normal;
    }

    &_Icon {
      width: 32px;
      height: 32px;
    }
  }
}

.calc {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  grid-gap: 24px;
  gap: 24px;
  padding: 16px 24px;
  border: 1px solid var(--color-brand-primary-shade, #029AAD);
  border-radius: 8px;
  background: var(--color-neutrals-turquoise, #EAF4F6);

  &_Title {
    color: var(--color-neutrals-black, #000);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
  }

  &_Offer {
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }


  &_Banks,
  &_Price {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &_Month {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
  }

  &_Cost {
    color: var(--color-neutrals-black, #000);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    width: max-content;

    @media (max-width:1080px) {
      font-size: 16px;
      line-height: 18px;
    }
  }

  &_ChooseBtn {
    background-color: var(--color-brand-secondary-shade) !important;
    color: #fff;

    @media (max-width: 768px) {
      padding: 12px 14px !important;
    }
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 10px;
    padding: 12px 14px;

    &_Title {
      font-size: getVwD(46px);
    }

    &_Offer {
      font-size: getVwD(46px);
      margin-bottom: 14px;
    }

    &_Banks {
      gap: 16px;
    }
  }
}

$assetPath: "/assets/icons";

.points {
  position: relative;
  display: inline-block;
  width: fit-content;
  margin-bottom: 24px;

  &>select {
    width: 286px;
    height: 44px;
    padding: 10px 32px 10px 36px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none;
    font-family: Inter, sans-serif;
    outline: none;
    cursor: pointer;
  }

  &::after {
    content: "";
    height: 20px;
    width: 20px;
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    filter: contrast(0%);
    background: url(#{$assetPath}/CurveDown.svg) no-repeat;
    background-position: center;
    pointer-events: none;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 14px;

    &>select {
      width: 100%;
    }
  }
}
