@import "global/styles/functions.scss";

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
  padding: 16px;
  border: 1px solid var(--color-upper-header);
  border-radius: 8px;
  background: var(--color-neutrals-white);
  z-index: 1;

  transition: box-shadow .4s;

  &:hover .buttonContainer {
    visibility: visible;
  }

  @media (min-width: 1080px) {
    &.active:hover {
      border-radius: 8px 8px 0 0;
      z-index: 2;

      -webkit-box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.10), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);
      -moz-box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.10), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);
      box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.10), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);

      .productFooter {
        visibility: visible;
      }

      .productFooter {
        max-height: 1000px;
      }
    }
  }

  @media(max-width: 1080px) {
    padding: 16px 10px;
    gap: 10px;
  }

  @media (max-width: 640px) {
    padding: 2.67vw;
    gap: 2.67vw;
  }
}

.imagePlaceholder {
  height: 100%;
}

.top {
  position: absolute;
  top: 16px;
  left: 16px;
  display: flex;
  justify-content: space-between;
  z-index: 2;
  width: 88%;

  @media (max-width: 768px) {
    position: relative;
    top: auto;
    left: auto;
    height: 24px;
    width: 100%;
    margin-bottom: -12px;
  }
}

.badge {
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 5px;
  border-radius: 4px;
  color: var(--color-neutrals-white);

  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media(max-width: 1080px) {
    height: 24px;
    padding: 5px;
  }

  @media (max-width: 640px) {
    height: 5.5vw;
    padding: 1vw;
    font-size: 2.8vw;
    line-height: 3.73vw;
  }
}

.showcase {
  background-color: var(--color-neutrals-white);
  width: max-content;
}

.arrowIcon {
  fill: white;
  stroke: white;
  stroke-width: 0.5;
  transition: .3s;
}

.arrowIconActive {
  transform: scale(-1);
}

.otherBadges {
  position: absolute;
  top: 38px;
  left: 0;
  width: 100%;
  padding: 6px 16px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 5px;
  z-index: 1;

  @media(max-width: 1080px) {
    top: 38px;
  }

  @media (max-width: 640px) {
    padding: 4px 10px;
    top: 28px;
  }

  &__top {
    top: 6px;

    @media (max-width: 768px) {
      top: 0;
    }
  }
}

.activeBadge {
  width: fit-content;
  padding: 1px 6px;
  border-radius: 4px;
  background: var(--color-brand-primary);
  color: var(--color-neutrals-white);

  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media(max-width: 1080px) {
    height: 24px;
    padding: 5px;
  }

  @media (max-width: 640px) {
    height: 5.5vw;
    padding: 1vw;
    font-size: 2.8vw;
    line-height: 3.73vw;
  }
}

.buttonContainer {
  display: flex;
  gap: 5px;
  margin-left: auto;
  visibility: hidden;

  @media (max-width: 1080px) {
    visibility: visible;
  }
}

.buttons {
  width: 23px;
  height: 23px;
  padding: 2px;
  border-radius: 3px;
  background-color: var(--color-neutrals-white, #fff);
  cursor: pointer;
  transition: background .2s ease;
  fill: var(--color-brand-primary-shade);

  @media (min-width: 1080px) {
    &:hover {
      fill: var(--color-neutrals-white);
      background-color: var(--color-brand-primary);
    }
  }

  @media (max-width: 640px) {
    width: 5.5vw;
    height: 5.5vw;
  }
}

.imageContainer {
  position: relative;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 210px;

    @media (max-width: 640px) {
      min-height: 40vw;
      height: auto;
    }
  }
}

.image {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    height: 20vh;
  }
}

.giftIcon {
  position: absolute;
  right: 0;
  bottom: 5px;

  @media (max-width: 768px) {
    right: 10px;
    bottom: 0;
  }

  >path {
    fill: #FFCD00;
  }
}

.description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.28px;
  text-decoration: none;
  color: var(--color-neutrals-black);
  cursor: pointer;
  overflow: hidden;
  transition: .1s;

  &:hover {
    color: var(--color-brand-secondary);
  }

  @media(max-width: 640px) {
    -webkit-line-clamp: 3;
    height: 15vw;

    font-size: 3.3vw;
    font-weight: 400;
    line-height: inherit;
    letter-spacing: -0.28px;
  }
}

.priceContainer {
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1080px) {
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    margin-top: -8px;
  }
}

.priceContainerLoad {
  width: 100%;
}

.placeholder {
  margin: 0;
}

.placeholderWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 0;
  gap: 8px;
}

.oldPriceContainer {
  font-size: 12px;
  line-height: normal;

  @media(max-width: 640px) {
    font-size: 2.67vw;
    line-height: 4.8vw;
    white-space: nowrap;
  }
}

.creditAndInstallmentInfo {
  padding: 4px 2px;
  border-radius: 4px;
  opacity: 0.8;
  background: var(--Evrika-turquoise-shade, #EAF4F6);

  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  color: var(--color-Emperor);
}

.creditAndInstallmentPrice {
  font-weight: 600;
  color: var(--color-brand-primary-shade);
}

.costContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 768px) {
    flex-direction: row-reverse;
    align-items: center;
    gap: 3px;
  }
}

.oldPrice {
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  text-decoration-line: line-through;
  color: var(--color-location);

  @media (max-width: 640px) {
    font-size: 2.67vw;
    font-weight: 700;
    line-height: normal;
  }
}

.oldPriceProcent {
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;

  border-radius: 4px;
  background: var(--color-labels-red);
  color: var(--color-neutrals-white);
  padding: 6px;

  @media (max-width: 768px) {
    left: 10px;
    top: 47%;
    padding: 5px;
  }
}

.currentPrice {
  margin-top: 4px;
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;

  color: var(--color-neutrals-black);

  @media (max-width: 1280px) {
    font-size: 14px;
  }

  @media(max-width: 640px) {
    font-size: 4vw;
    line-height: 4.8vw;
    margin-top: 0;
  }
}

.basket {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 100%;
  height: 40px;
  padding: 10px 16px;

  border: none;
  outline: none;
  border-radius: 10px;
  background: var(--color-brand-primary);
  fill: var(--color-neutrals-white);

  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  color: var(--color-neutrals-white);
  text-decoration: none;

  >svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background: var(--color-brand-primary-shade);
    color: var(--color-neutrals-white);
  }

  &[disabled] {
    box-shadow: none;
    background: #C0DEE0;
  }

  @media(max-width: 640px) {
    width: 100%;
    height: 8.8vw;
    padding: 10px 16px;

    font-size: 3.2vw;
    font-weight: 700;
    line-height: normal;

    >svg {
      display: none;
    }
  }

  &__load {
    width: 80%;

    @media (max-width: 640px) {
      width: 27vw;
    }
  }
}

[data-channel="samsung"] .basket{
  background: var(--color-brand-primary-shade);
  &:hover{
    background: var(--color-brand-primary);
  }
}

.productFooter {
  visibility: hidden;
  position: absolute;
  top: 97%;
  left: -1px;
  width: calc(100% + 2px);
  max-height: 0;
  overflow: hidden;
  transition: max-height .4s;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  padding: 8px 16px;
  border: 1px solid var(--color-upper-header);
  border-top: none;
  border-radius: 0 0 8px 8px;
  background: var(--color-neutrals-white);

  -webkit-box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.10), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);
  -moz-box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.10), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);
  box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.10), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);
}

.creditDate {
  display: inline-block;
  width: fit-content;
  padding: 4px 8px;
  margin-bottom: 8px;
  border-radius: 4px;
  background: var(--color-neutrals-turquoise);
  opacity: 0.8;
}

.creditOtherColor {
  color: var(--color-brand-primary-shade);
  margin-right: 4px;
}

.productGift {
  display: flex;
  gap: 12px;
  margin-top: 16px;
}

.dashed {
  border-top: 1px dashed var(--color-neutrals-border-solid);
}

.productGiftImage {
  max-height: 70px;
  border: 2px solid var(--color-labels-yellow);
  border-radius: 8px;
}

.productGiftTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #0D0E0F;
  margin-bottom: 6px;
}

.productGiftDescription {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: var(--color-neutrals-black);
}

.inCart {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-neutrals-white);
  color: var(--color-brand-primary);
  border: 1px solid var(--color-brand-primary);

  >svg {
    >path {
      fill: var(--color-brand-primary);
    }
  }

  &:hover {
    background: var(--color-neutrals-white);
    color: var(--color-brand-primary);
    border: 1px solid var(--color-brand-primary);
  }

  @media (max-width: 1080px) {
    text-align: center;
  }
}
[data-channel="samsung"] .inCart{
  background: var(--color-neutrals-white);
  color: var(--color-brand-primary-shade);
  border: 1px solid var(--color-brand-primary-shade);
  >svg {
    >path {
      fill: var(--color-brand-primary-shade);
    }
  }
  &:hover{
    background: var(--color-neutrals-white);
    color: var(--color-brand-primary-shade);
  }
}
.productCard_btn {
  display: flex;
  width: 100%;
  padding: 0;
}

.favoriteBtn {
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 40px;
  height: 40px;
  margin-left: 8px;

  border-radius: 10px;
  border: 1px solid var(--color-brand-primary);
  background: var(--color-neutrals-white);
  cursor: pointer;

  >svg {
    width: 20px;
    height: 18.141px;

    >path {
      fill: var(--color-neutrals-white);

      &:last-child {
        fill: var(--color-brand-primary);
      }
    }
  }

  @media (min-width: 1080px) {
    &:hover {
      border: 1px solid var(--color-brand-primary);

      >svg {
        >path {
          fill: var(--color-brand-primary);
        }
      }
    }
  }

  @media (max-width: 640px) {
    min-width: 8.8vw;
    height: 8.8vw;

    >svg {
      width: 5.3vw;
      height: 4.8vw;
    }
  }

  &__load {
    width: 40px;
    border: none;

    @media (max-width: 640px) {
      min-width: 12vw;
    }
  }
}

.activeBtn {
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 40px;
  height: 40px;
  margin-left: 8px;

  border-radius: 10px;
  border: 1px solid var(--color-brand-primary);
  background: var(--color-neutrals-white);
  cursor: pointer;

  >svg {
    width: 20px;
    height: 18.141px;

    >path {
      fill: var(--color-brand-primary);

      &:last-child {
        fill: var(--color-brand-primary);
      }
    }
  }

  @media (max-width: 640px) {
    min-width: 8.8vw;
    height: 8.8vw;

    >svg {
      width: 5.3vw;
      height: 4.8vw;
    }
  }

  &__load {
    width: 40px;
    border: none;

    @media (max-width: 640px) {
      min-width: 12vw;
    }
  }
}
[data-channel="samsung"] .activeBtn {
  >svg {
    width: 20px;
    height: 18.141px;

    >path {
      fill: var(--color-brand-primary-shade);

      &:last-child {
        fill: var(--color-brand-primary-shade);
      }
    }
  }
}

.compareActive {
  fill: var(--color-neutrals-white);
  background-color: var(--color-brand-primary);
}

.costCompare {
  flex-direction: row-reverse;
}

.modalTitle {
  padding: 16px 24px;
  text-align: center;
  font-size: 22px;
  line-height: normal;
  font-weight: 600;
  color: #313234;

  @media (max-width: 768px) {
    padding: 11px 24px;
    font-size: 14px;
    font-weight: 400;
  }
}

.modalSubtitle {
  padding: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  cursor: pointer;

  @media (max-width: 768px) {
    padding: 10px 16px;
    font-size: 12px;
    line-height: 15px;
  }
}

.favourites {
  color: var(--color-brand-primary-shade);
  border-bottom: 2px solid var(--color-upper-header);

  &:hover {
    color: var(--color-brand-secondary);
  }
}

.deleteTitle {
  color: #FF3B30;
  border-bottom: 2px solid var(--color-upper-header);

  &:hover {
    color: var(--color-brand-secondary);
  }
}

.backTitle {
  color: var(--color-Woodsmoke);
  padding-bottom: 24px;

  @media(max-width: 768px) {
    padding-bottom: 16px;
  }

  &:hover {
    color: var(--color-brand-secondary);
  }
}

.modalWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
.questionTooltip {
  padding: 16px;
  border-radius: 8px;
  background: var(--color-neutrals-grey, #F2F2F2);

  box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.10), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);

  color: var(--color-Emperor, #4F4F4F);

  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;

  z-index: 1;
  /* 125% */

  &__content {
    position: relative;
    background: inherit;

    &::before {
      content: '';
      position: absolute;
      top: -18px;
      left: 50%;

      width: 18px;
      height: 18px;
      background: var(--color-neutrals-grey, #F2F2F2);
      transform: rotate(45deg);
      z-index: -1;
    }
  }

  p:last-child {
    margin-bottom: 0;
  }
}
.questionSVG{
  fill:#fff
}