@import "global/styles/functions.scss";
.wrapper {
    position: relative;
    width: 100%;
    label {
        position: absolute;
        top: 50%;
        left: 24px;
        transform: translateY(-50%);
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
        pointer-events: none;
        transition: 0.3s;
        color: var(--color-location);
        // @media (max-width: 1280px) {
        //   font-size: getVwD(16px);
        //   line-height: getVwD(26px);
        // }
        @media (max-width: 640px) {
            font-size: 3.73vw;
            line-height: normal;
        }
    }
}

.input {
    width: 100%;
    height: 50px;
    padding: 20px 24px 6px;
    border-radius: 8px;
    border: 1px solid var(--color-neutrals-border-solid);
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    &[type="password"] {
        background-position-x: calc(100% - 35px);
    }
    &:focus-visible {
        outline: none;
    }
    &[disabled] {
        background: var(--color-dark-Silver);
    }
    // @media (max-width: 1280px) {
    //   height: getVwD(50px);
    //   padding: getVwD(10px) getVwD(24px);
    //   font-size: getVwD(16px);
    //   line-height: getVwD(26px);
    // }
    @media (max-width: 640px) {
        height: 12vw;
        padding: 4vw 5vw;
        font-size: 3.73vw;
        line-height: normal;
    }
}

.input:focus~label,
.input:active~label,
.label {
    top: 12px !important;
    left: 24px !important;
    font-size: 10px !important;
    // @media (max-width: 1280px) {
    //   top: getVwD(12px) !important;
    //   left: getVwD(24px) !important;
    //   font-size: getVwD(10px) !important;
    // }
    @media (max-width: 640px) {
        top: 2.67vw !important;
        left: 5.27vw !important;
        font-size: 2.13vw !important;
        line-height: normal !important;
    }
}

.eyeIcon {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    transition: color .3s;
    cursor: pointer;
}