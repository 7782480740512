.placeholder {
  margin-right: 6px;
  border-radius: 4px;
}
.placeholder.title-placeholder {
  min-height: 24px;
}
.placeholder.image-placeholder {
  display: block;
  min-height: 240px;
  background-image: url(/assets/evrika_logo_1200_628_white.png);
  background-position: center center;
  background-repeat: no-repeat;
  margin-bottom: 16px;
  border-radius: 8px;
  margin-right: 0;

  @media (max-width: 768px) {
    min-height: 150px;
    margin-bottom: 0;
  }
}
.placeholder.map-placeholder {
  display: block;
  min-height: 600px;
  background-image: url(/assets/evrika_logo_1200_628_white.png);
  background-position: center center;
  background-repeat: no-repeat;
  margin-bottom: 16px;
  border-radius: 8px;
  margin-right: 0;

  @media (max-width: 768px) {
    min-height: 90vw;
    margin-bottom: 0;
  }
}
.text-placeholder {
  padding: 16px 0;
}

.bg-50 {
  background-size: 50%;
}

// Buttons
.button {
  display: inline-block;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  background-color: inherit;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  white-space: nowrap;
  text-decoration: none;
  transition: all 200ms ease-in-out;

  // @media (max-width: 1280px) {
  //   & {
  //     padding: getVwD(12px) getVwD(24px);
  //     font-size: getVwD(16px);
  //   }
  // }

  @media (max-width: 640px) {
    & {
      height: 12vw;
      padding: 3vw 5vw;
      font-size: 3.73vw;
      line-height: normal;
    }
  }

  &.fullWidth {
    display: block;
    width: 100%;
  }

  // blue button, old class: button_three
  &.blue, &.button_three {
    background-color: var(--color-brand-primary);
    color: white;
  }
  &.blue:hover, &.blue:active, &.blue:focus,
  &.button_three:hover, &.button_three:active, &.button_three:focus {
    background-color: var(--color-brand-primary-shade);
  }
  &.orrange {
    background-color: var(--color-brand-secondary);
    color: white;
  }
  &.orrange:hover, &.orrange:active, &.orrange:focus {
    background-color: var(--color-brand-secondary-shade);
  }
}
[data-channel="samsung"] .button{
  &.orrange {
    background-color: var(--color-brand-primary-shade);
    color: white;
  }
  &.orrange:hover, &.orrange:active, &.orrange:focus {
    background-color: var(--color-brand-primary);
  }
}

// Border radius
.br-10 {
  border-radius: 10px;
  overflow: hidden;

  @media (max-width: 1280px) {
    & {
      border-radius: getVwD(10px);
    }
  }

  @media (max-width: 768px) {
    & {
      border-radius: getVwM(16px);
    }
  }
}

.br-8 {
  border-radius: 8px;
  overflow: hidden;

  @media (max-width: 1280px) {
    & {
      border-radius: getVwD(8px);
    }
  }

  @media (max-width: 768px) {
    & {
      border-radius: getVwM(12px);
    }
  }
}

.content-wrapper-white {
  background-color: #FFF;
  padding: 24px;
  @media (max-width: 1280px) {
    & {
      padding: getVwD(24px);
    }
  }

  @media (max-width: 768px) {
    & {
      padding: getVwM(24px);
    }
  }
}
