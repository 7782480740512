@import "global/styles/functions.scss";

.content {
  position: relative;
  display: flex;
  gap: 22px;

  margin-bottom: 24px;

  @media (max-width: 1280px) {
    gap: 8px;
    z-index: 1;
  }

  @media (max-width: 1080px) {
    flex-direction: column;
  }

  &__webview {
    @media (max-width: 768px) {
      margin: -5vw 0;
    }
  }
}

.breadcrumbs {
  display: block;
  overflow: auto hidden;
  list-style: none;
  flex: 1 1;
  white-space: nowrap;
  width: 100%;
  margin: -16px 0 16px;

  @media (max-width: 1080px) {
    margin: 0px 0 16px;
  }

  @media (max-width: 768px) {
    margin: -32px 0 8px;
  }

  @media (max-width: 640px) {
    margin: -16px 0 8px;
  }

  > ul {
    margin: 0;
    padding: 0;

    @media (max-width: 768px) {
      display: block;
    }
  }
}

.rightContent {
  flex: 2.2;
  border-radius: 8px;
}

.leftContent {
  flex: 1;
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 8px;
}

.placeholder {
  &_tabs {
    height: 500px;
    padding: 32px 30px;
    border-radius: 8px;
    background: var(--neutrals-white, #fff);

    & > span {
      height: 100%;
    }
  }

  &_routerMap {
    width: 630px;
    height: 18px;
    padding-left: 0;
    margin-top: -16px;
    margin-bottom: 24px;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
}

.tabContent {
  margin-top: 32px;
  padding: 32px 30px;
  border-radius: 8px;
  background: var(--neutrals-white, #fff);

  &__title {
    margin-bottom: 25px;
    font-size: 22px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.22px;
    color: var(--color-Woodsmoke);

    @media (max-width: 1280px) {
      margin-bottom: getVwD(25px);
      font-size: getVwD(22px);
    }
  }

  @media (max-width: 768px) {
    margin-top: 0;
    background: transparent;
    padding: 0;

    &__web {
      margin-bottom: 64px;
    }
  }
}

.tabContainer {
  display: flex;
}

.mobileTabs {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.item {
  color: var(--color-neutrals-black, #000);
  /* Web 1440px/B6 */
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.28px;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }

  & > svg {
    margin-right: 8px;
    fill: #c4c4c4;
  }
}

.giftHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  & > h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    color: var(--color-neutrals-black, #000);
  }

  & > a {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: var(--color-brand-secondary, #FB791B);
  }
}

.giftHead__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  height: 40px;
  border-radius: 8px;
  background: var(--color-brand-secondary, #FB791B);
  color: var(--neutrals-white, #FFF);
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  cursor: pointer;
}

.giftHead__timer {
  display: flex;
  width: 20%;
  flex-direction: column;
  gap: 8px;
}

.banner_bonus {
  max-width: 100%;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 8px;
}

.accoridionMethods {
  &_Wrap {
    margin: 0 -10px;
    border-radius: 0 !important;
  }

  &_Title {
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    color: #3c3c43;
  }
}
