.errorText {
  display: block;
  color: red;
  font-size: 11px;
  line-height: 1;
  margin-top: 4px;
  text-align: left;
  width: 100%;
}

.errorInput {
  background: url("data:image/svg+xml;utf8,<svg fill='rgb(248, 65, 71)' width='16' height='14' viewBox='0 0 14.56 12.98' xmlns='http://www.w3.org/2000/svg'><path d='m14.42 11.49-6.27-10.99a1 1 0 0 0 -1.74 0l-6.28 11a1 1 0 0 0 .87 1.5h12.56a1 1 0 0 0 .86-1.51zm-6.14-.49a1 1 0 1 1 -2 0v-1a1 1 0 1 1 2 0zm0-4a1 1 0 1 1 -2 0v-3a1 1 0 0 1 2 0z'/></svg>") right 12px center no-repeat #fff;
  border-color: red !important;
  padding-right: 40px;
}

.form {
  margin-top: 16px;
}

.button {
  width: 100%;
  margin-top: 8px;
  background-color: var(--color-brand-primary);
  color: var(--color-neutrals-white);
  border: none;
  border-radius: 8px;
  padding: 8px;
  height: 50px;
  font-size: 16px;

  &:hover {
    background-color: var(--color-brand-primary-shade);
  }

  @media(max-width: 1080px) {
    line-height: 1;
  }

  @media (max-width: 640px) {
    height: 12vw;
    font-size: 14px;
  }
}

[data-channel="samsung"] .button{
  background-color: var(--color-brand-primary-shade);
  &:hover{
    background-color: var(--color-brand-primary);
  }
}
