@import "global/styles/functions.scss";

.upperHeader {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  background: var(--color-neutrals-white);
  padding: 7px 10px;
  border: 1px solid var(--color-neutrals-border-solid);
  border-radius: 30px;
  cursor: pointer;
  color: var(--color-location);
  font-weight: 400;
  line-height: 1;
  vertical-align: top;
  transition: 0.2s ease;

  &:hover {
    border-color: var(--color-brand-primary-shade);
    background: var(--color-brand-primary-shade);

    & > span {
      color: var(--color-neutrals-white);
      transition: color 0.2s ease;

      svg {
        > [data-blue] {
          fill: var(--color-neutrals-white);
        }
        > [data-orange] {
          fill: var(--color-brand-secondary);
        }
        > [data-white] {
          fill: var(--color-brand-primary-shade);
        }
      }
    }
  }
}

[data-channel='samsung'] .upperHeader> span svg{
  > [data-blue] {
    fill: var(--color-brand-primary-shade);
  }
  > [data-orange] {
    fill: var(--color-brand-primary-shade);
  }
  > [data-white] {
    fill: var(--color-neutrals-white);
  }
}

[data-channel='samsung'] .upperHeader:hover > span svg{
  > [data-blue] {
    fill: var(--color-neutrals-white);
  }
  > [data-orange] {
    fill: var(--color-neutrals-white);
  }
  > [data-white] {
    fill: var(--color-brand-primary-shade);
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-neutrals-white);
    border: 1px solid var(--color-neutrals-border-solid);
    border-radius: 30px;
    padding: 4px 10px;
    transition: all 0.3s;

    &:hover,
    &[aria-describedby] {
      border-color: var(--color-brand-primary-shade);
      background: var(--color-brand-primary-shade);

      span {
        color: var(--color-neutrals-white);
      }

      svg {
        fill: var(--color-neutrals-white);
      }
    }

    &:active,
    &:first-child:active {
      border-color: var(--color-brand-primary-shade) !important;
      background: var(--color-brand-primary-shade);

      span {
        color: var(--color-brand-primary-shade);
      }

      svg {
        fill: var(--color-brand-primary-shade);
      }
    }
  }
}

.iconPhone {
  margin-right: 8px;
  width: 24px;
  fill: var(--color-brand-primary-shade);
}

.title {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #4f4f4f;

  &_load {
    width: 60px;
  }
}

.iconDown {
  width: 16px;
  height: 16px;
  margin-left: 8px;
  color: var(--color-neutrals-black);
  stroke-width: 1;
}

.tel {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: var(--color-neutrals-black);
  text-decoration: none;
  transition: ease 0.2s;

  &:hover {
    color: var(--color-brand-secondary);
  }
}

.time {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: var(--color-location);
}

.link {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: var(--color-neutrals-black);
  text-decoration: none;
  transition: ease 0.2s;

  &:hover {
    color: var(--color-brand-secondary);
  }
}

.langSwitcher {
  display: inline-flex;
  gap: 8px;
  margin-left: auto;

  & > button {
    font-size: 12px;
    font-family: Inter;
    font-weight: 500;
    padding: 0;
    border: none;
    background: none;
    outline: none;
    color: var(--color-brand-primary-shade);

    &:active {
      color: var(--color-labels-orange);
      border-color: none !important;
      background: none !important;
    }

    &:hover {
      color: var(--color-labels-orange);
      background: none;
    }
  }
}
[data-channel='samsung'] .langSwitcher > button:active{
  color: var(--color-brand-secondary) !important;
}
[data-channel='samsung'] .langSwitcher > button:hover{
  color: var(--color-brand-secondary) !important;
}

.langSwitcherActive {
  color: var(--color-labels-orange) !important;
}
[data-channel='samsung'] .langSwitcherActive {
  color: var(--color-brand-secondary) !important;
}

.cities {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 400px;
  max-height: 800px;
  padding: 20px 44px;
  padding-top: 60px;
  overflow-y: auto;
  overflow-x: hidden;

  &__header {
    width: 100%;
    margin-bottom: 28px;
    padding-bottom: 24px;
    border-bottom: 1px solid #e0e0e0;

    text-align: center;
    font-weight: 700;
    font-size: 36px;
  }

  &__columns {
    display: flex;
    gap: 8px;
  }

  &__column {
    width: 200px;
    display: flex;
    flex-direction: column;
    gap: 13px;
  }

  &__item {
    cursor: pointer;

    input {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    input:checked ~ span {
      color: #202020;
      font-weight: 700;

      &:before {
        border-width: 2px;
        border-color: var(--color-brand-secondary);
        background: var(--color-brand-secondary);
        box-shadow: inset 0 0 0 2px #fff;
      }
    }

    span {
      align-items: center;
      color: #4f4f4f;
      display: flex;
      font-size: 15px;
      line-height: 1.2;

      &:before {
        border-radius: 50%;
        border: 1px solid #bdbdbd;
        content: "";
        flex-shrink: 0;
        margin-right: 14px;
        height: 20px;
        width: 20px;
      }
    }

    &:hover span {
      color: var(--color-brand-secondary);

      &:before {
        border-color: var(--color-brand-secondary);
      }
    }
  }

  &__notice {
    max-width: 400px;
    margin-top: 30px;

    color: #999;
    font-size: 12px;
    line-height: 1.25;
  }
}

.button {
  background-color: var(--color-brand-primary);
  font-size: 16px;
  line-height: 26px;
  color: var(--color-neutrals-white);
  margin-top: 30px;

  &:hover {
    background-color: var(--color-brand-primary-shade);
  }

  @media (max-width: 1280px) {
    font-size: getVwD(16px);
    line-height: getVwD(26px);
  }
}

[data-channel="samsung"] .button{
  background-color: var(--color-brand-primary-shade);
  &:hover{
    background-color: var(--color-brand-primary);
  }
}
.closeBtn {
  position: absolute;
  top: 16px;
  right: 16px;
  display: inline-flex;
  width: 44px;
  height: 44px;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: inherit;
  border-radius: 100%;

  &:hover {
    color: #202020;
    background: hsla(0, 0%, 60%, 0.1);
  }
}

.channels {
  justify-content: center;
}

.channelsSwitch {
  position: relative;
}
.channelsSwitch::after {
  content: "";
  position: absolute;
  top: 10px;
  right: 0;
  height: 22px;
  border-right: 1px solid lightgray;
}
.retailGroup {
  font-size: getVwD(10px);
  padding-top: 10px;
  color: var(--color-brand-primary-shade) !important;
}
[data-channel="samsung"] .retailGroup {
  font-size: getVwD(10px);
  color: var(--color-brand-secondary) !important;
}

.iconHeader{
  width: 18px;
  height: 18px;
  margin-right: 5px;
}
[data-channel="samsung"] .iconHeader{
  
}
