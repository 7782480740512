.background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0000003f;
    z-index: 2;
}

.container {
    $this: &;
    display: inline-flex;
    flex-direction: column;
    position: relative;
    width: 308px;
    height: clamp(502px, 502px, 502px);
    border-radius: 8px;
    background: var(--color-neutrals-white);
    transition: .02s linear;
    * {
        transition: .02s linear;
    }
    &:hover {
        z-index: 3;
    }
    &.hidden {
        display: none !important;
    }
}

.linkContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    overflow-y: auto;
}

.placeholderContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
}

.placeholder {
    width: calc(100% - 44px);
    height: 32px;
    margin: 0 22px;
}

.linkContainer {
    a {
        text-decoration: none;
        >img {
            width: 24px;
            height: 24px;
        }
    }
}

.link {
    display: flex;
    align-items: center;
    gap: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.02em;
    padding: 12px 22px;
    color: var(--color-Mine-Shaft);
    cursor: pointer;
    &:hover {
        font-weight: 600;
        color: var(--color-neutrals-black);
        &>.arrow {
            fill: var(--color-brand-primary-shade);
            stroke: var(--color-brand-primary-shade);
            stroke-width: 1px;
        }
        &~.content {
            display: block;
        }
    }
}

.arrow {
    margin-left: auto;
    fill: var(--color-neutrals-border-solid);
}

.content {
    display: none;
    position: absolute;
    top: 0;
    left: 295px;
    max-width: 1080px;
    width: 70vw;
    height: 100%;
    padding: 24px 42px;
    border-radius: 0 8px 8px 0;
    box-sizing: border-box;
    overflow-y: auto;
    background-color: var(--color-neutrals-white);
    &:hover {
        display: block;
    }
    &.hidden {
        display: none !important;
    }
}

.title {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 24px;
    color: var(--color-neutrals-black);
}

.contentLinks {
    max-height: 100%;
    padding: 0;
}

.linkItems {
    display: inline-block;
    margin-top: 16px;
    &>a {
        display: block;
        line-height: 2;
    }
}

.allCategories,
.topLink,
.otherLinks {
    max-width: 100%;
    white-space: pre-wrap;
    &:hover {
        color: var(--color-brand-secondary);
    }
}

.topLink {
    font-weight: 700;
    font-size: 14px;
    color: var(--color-brand-primary-shade);
}

.otherLinks {
    font-weight: 400;
    font-size: 13px;
    color: var(--color-neutrals-black);
}

.allCategoriesContainer {
    padding: 0 24px;
    margin-top: auto;
    margin-bottom: 24px;
}

.allCategories {
    display: block;
    padding-top: 16px;
    border-top: 1px solid #E0E0E0;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 1.44px;
    text-transform: uppercase;
    text-decoration: none;
    color: var(--color-location);
}

div,
ul {
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: var(--color-neutrals-border-solid);
        &:hover {
            background: var(--color-silver);
        }
        &:active {
            background: var(--color-checkmark);
        }
    }
    &::-webkit-scrollbar-track {
        background: 0 0;
    }
}

.contentHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.allCategoriesContent {
    vertical-align: middle;
    color: var(--color-Mine-Shaft);
    font-size: 14;
    line-height: 1.22;
    text-decoration: none;
    transition: color 0.15s;
    margin-left: auto;
    svg {
        border-radius: 50%;
        fill: var(--color-brand-primary-shade);
        flex-shrink: 0;
        margin-left: 4px;
        transition: 0.2s ease;
    }
    &:hover {
        color: var(--color-brand-secondary);
        svg {
            background: white;
            fill: var(--color-brand-secondary);
            box-shadow: 0 4 8 rgba(0, 0, 0, 0.15);
        }
    }
}
