@font-face {
  font-family: "Inter";
  src: local("Inter"), url("fonts/Inter/static/Inter-Black.ttf") format("truetype");
  font-weight: 900;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("fonts/Inter/static/Inter-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("fonts/Inter/static/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("fonts/Inter/static/Inter-Medium.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("fonts/Inter/static/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("fonts/Inter/static/Inter-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("fonts/Inter/static/Inter-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("fonts/Inter/static/Inter-ExtraLight.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("fonts/Inter/static/Inter-Thin.ttf") format("truetype");
  font-weight: 100;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.align-center {
  align-items: center;
}
.align-end {
  align-items: flex-end;
}

.form-check-input[type=radio], .form-check-input[type=radio]:hover:checked {
  border: 1px solid #C4C4C4;
}
.form-check-input[type=radio]:disabled {
  border: 2px solid #BDBDBD !important;
}
.form-check-input[type=radio]:active {
  border: 2px solid #BDBDBD !important;
}
.form-check-input[type=radio]:checked {
  background-color: var(--color-brand-primary-shade);
  border-color: var(--color-brand-primary-shade);
  cursor: pointer;
}

[data-channel="samsung"] .form-check-input[type=radio]:checked {
  background-color: var(--color-brand-secondary-shade);
  border-color: var(--color-brand-secondary-shade);
}

.form-check-input[type=radio]:hover {
  border: 4px solid var(--color-brand-primary-shade);
}

[data-channel="samsung"] .form-check-input[type=radio]:hover {
  border: 4px solid var(--color-brand-secondary-shade);
}

.form-check-input[type=radio]:focus {
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.1);
}
.form-check-input[type=checkbox] {
  border: 1px solid var(--color-brand-secondary);
}

.form-check-input[type=checkbox]:disabled {
  border: 2px solid #BDBDBD !important;
}
.form-check-input[type=checkbox]:active {
  border: 2px solid #BDBDBD !important;
}
.form-check-input[type=checkbox]:checked {
  background-color: var(--color-brand-secondary);
  border-color: var(--color-brand-secondary);
}

.form-check-input[type=checkbox]:focus {
  box-shadow: none;
}
.body-padding {
  padding: 32px 0;
}
.icon-header{
  width: 18px;
  height: 18px;
  margin-right: 5px;
}
.unstyled-anchor:hover {
  text-decoration: none;
}
a.hover-brand-color:hover {
  color: var(--color-brand-secondary) !important;
}
.footer-bottom{
  color: rgba(255, 255, 255, 0.7);
  background: #141a38;
}
.dropdown-toggle::after {
  margin: 0 !important;
  border: none !important;
}
.dropdown-menu.show {
  transform: translate(0px, 43.5556px);
  padding: 0px;
  border-radius: 0 0 8px 8px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-top: none;
  z-index: 3 !important;
  max-height: 230px;
  overflow: auto;
  margin-bottom: -3px;
}

.bold {
  font-weight: 700;
}

.regular {
  font-weight: 400;
}

.contact-active {
  background-color: #EAF4F6;
  border: 2px solid #029AAD !important;
}

.contact-active > span {
  font-weight: 500;
  color: #029AAD;
}

.contact-active > img {
  filter: none;
}

.active>.page-link, .page-link.active {
  z-index: 1 !important;
}
@media (max-width: 1080px) {
  .body-padding {
    padding: 16px;
  }
}

@media (max-width: 768px) {
  .body-padding {
    padding: 5vw 0;
  }
}

.dropdown-menu {
  width: 100%;
}

.placeholder-wave {
  width: 100%;
}

.react-datepicker-wrapper {
  width: 50%;
  margin-right: 16px;
}

@media (max-width: 768px) {
  .react-datepicker-wrapper { 
    width: 100%;
  }
}

.react-datepicker__input-container > input {
  width: 100%;
  height: 50px;
  border-radius: 8px;
  border: 1px solid var(--color-neutrals-border-solid);
  font-size: 16px;
}

.react-datepicker__calendar-icon {
  width: 2em !important;
  height: 2em !important;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  fill: var(--color-brand-primary-shade);
}

.react-datepicker__input-container:focus-visible {
  outline: none;
}

.react-datepicker__input-container > input:focus-visible {
  outline: none;
}

.cartPopup:hover >.popup {
  display: block;
}

.swiper-wrapper {
  align-items: center;
}

.Toastify__toast {
  gap: 16px;
}

.Toastify__progress-bar--success {
  background: #039855 !important;
}

.Toastify__toast-theme--light {
  color: #999999;
  font-size: 16px;
}

.Toastify__toast-container {
  width: auto !important;
}

.Toastify__close-button {
  width: 24px;
  height: 24px;
  margin: auto 0;
  margin-bottom: 14px;
  opacity: 1 !important;
}

.Toastify__close-button >svg >path {
  fill: #FF3B30;
}

.Toastify__toast-body >div {
  color: #999999;
}

@media (max-width: 768px) {
  .Toastify__toast {
    margin: 0 auto 16px !important;
    max-width: 90%;
    white-space: normal;
    word-wrap: break-word;
  }

  .Toastify__toast-body {
    flex: auto;
  }

  .Toastify__toast-container {
    width: 100% !important;
  }
}

/* DEPRECATED */
input.rees46-instant-search {
  /* position: relative !important;
  z-index: 997 !important; */
}

.rees46-search-box {
  display: inline-block;
  position: relative;
  font-family: "Inter", sans-serif;
  color: #5a595f;
  font-size: 14px;
}

.rees46-search-box.rees46-search-box-body {
  position: absolute;
}

.rees46-search-box > input {
  float: none !important;
}

.rees46-search-overlay {
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
}

.rees46-search-close {
  display: none;
}

.rees46-search-results {
  font-family: "Inter", sans-serif !important;
  font-size: 14px;
  position: absolute;
  z-index: 9;
  top: calc(100% + 12px);
  max-width: 688px;
}
.rees46-instant-search-rendered {
    position: relative;
    z-index: 10;
}
.rees46-search-wrapper {
  border: 1px solid #EEE;
  border-radius: 8px;
  padding: 32px 16px;
  overflow: hidden;
  background: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}

.rees46-search-wrapper:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: #f0f0f0;
  top: -20px;
  left: 25px;
}

.rees46-search-wrapper:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: #ffffff;
  left: 25px;
  top: -19px;
}

.rees46-search-wrapper__right #rees46_search_products.rees46-search-container:empty {
  width: 471px;
}

.rees46-search-container:first-child:not(:empty) {
  min-width: 188px;
}

.rees46-search-container:not(:empty) {
  padding: 0 16px;
  -webkit-box-sizing: content-box !important;
          box-sizing: content-box !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.rees46-search-container:first-child:not(:empty) {
    padding-left: 0;
}

#rees46_search_products.rees46-search-container:not(:empty) {
  width: 420px;
  padding-right: 0;
  border-left: 1px solid #f0f0f0;
}

#rees46_search_products.rees46-search-container:not(:empty) .rees46-search-row {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  font-size: 14px;
  color: #485059;
}

#rees46_search_products.rees46-search-container:not(:empty) .rees46-search-row:hover {
  background: transparent;
}

#rees46_search_products.rees46-search-container:not(:empty) .rees46-search-row:after {
  content: '';
  display: block;
  width: 7px;
  height: 12px;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='7' height='12' viewBox='0 0 7 12'%3E%3Cdefs%3E%3Cpath id='a' d='M11.6 10.152l-4.017 4.149a1 1 0 1 0 1.43 1.398l4.702-4.847a1 1 0 0 0 0-1.4l-5-5.151a1 1 0 0 0-1.43 1.398l4.316 4.453z'/%3E%3C/defs%3E%3Cuse fill='%2375C3F9' fill-rule='nonzero' transform='translate%28-7 -4%29' xlink:href='%23a'/%3E%3C/svg%3E");
  margin-left: 11px;
}

.rees46-search-container:not(:empty):first-child:not(:last-child) .rees46-search-row {
  max-width: 232px;
}

.rees46-search-container:not(:empty) .recone-banner-block:not(:empty), .rees46-search-container:not(:empty) .recone-premium-block:not(:empty) {
  margin-top: auto;
  margin-bottom: 15px;
  width: 168px;
}

.rees46-search-container:not(:empty) .recone-banner-block:not(:empty):last-child, .rees46-search-container:not(:empty) .recone-premium-block:not(:empty):last-child {
  margin-bottom: 0;
}

.rees46-search-container:not(:empty) .recone-premium-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.rees46-search-container:not(:empty) .recone-premium-block .rees46-search-product {
  margin: 0;
}

.rees46-search-empty {
  width: 668px;
  max-width: 100%;
}

.rees46-search-group {
  margin-bottom: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.rees46-search-group-title {
  width: 100%;
  font-size: 16px;
  line-height: 1.5;
  color: #029AAD;
  font-weight: 600;
}

.rees46-search-group-description {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.rees46-search-products {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.rees46-search-products .rees46-search-group-title {
  width: auto;
  margin-bottom: 10px;
}

.rees46-search-products .rees46-search-group-description {
  padding: 0;
}
.rees46-search-products .rees46-search-group-description:nth-child(3) {
  -webkit-box-flex: 0;
      -ms-flex: 0;
          flex: 0;
}
.rees46-search-product {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  width: 100%;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #f0f0f0;
  -webkit-box-sizing: border-box !important;
          box-sizing: border-box !important;
}

.rees46-search-product * {
    -webkit-box-sizing: border-box !important;
    box-sizing: border-box !important;
}

.rees46-search-product:hover, .rees46-search-product__active {

}

.rees46-search-product__name-holder {
    flex: 0 0 84%;
    padding-left: 0.5rem;
}

.rees46-search-product__name {
  overflow: hidden;
  line-height: 1.3;
  margin: 0;
  margin-bottom: 0.625rem;
  padding: 0;
  font-size: 0.875rem;
  color: #485059;
}

.rees46-search-product__price {
  line-height: 1.24;
  font-size: 0.875rem;
  color: #485059;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  height: 1.5rem;
}
.rees46-search-product__price-current {
    font-weight: 700 !important;
    margin-right: 8px;
}
.rees46-search-product__price-old {
    line-height: 1;
    font-weight: normal;
    color: #999;
    text-decoration: line-through;
}
.rees46-search-product__price-discount {
    position: absolute;
    right: 0;
    font-size: 0.6875rem;
    padding: 0.25rem 0.1875rem 0.25rem 0.25rem;
    min-width: 2.125rem;
    color: #FFF;
    background-color: red;
    border-radius: 4px;
}
.rees46-search-product__image {
    position: relative;
    flex: 0 0 16%;
    width: 16%;
    padding-bottom: 16%;
    overflow: hidden;
    background: transparent center no-repeat;
    background-size: contain;
}

.rees46-search-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 8px 0;
    text-decoration: none;
    font-size: 0.875rem;
    line-height: 1.6;
    cursor: pointer;
}

.rees46-search-row > span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 232px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.rees46-search-row, .rees46-search-row:link, .rees46-search-row:active, .rees46-search-row:visited, .rees46-search-row:hover {
  color: #5a595f;
}

.rees46-search-row:hover, .rees46-search-row__active {
  /* background: rgba(47, 138, 227, 0.1); */
}

.rees46-search-box__suggested .rees46-search-group-title {
  display: none;
}

.rees46-search-box__suggested .rees46-search-group-description {
  padding: 0;
}

.rees46-search-box__suggested .rees46-search-container:not(:empty) {
  padding-left: 0;
}

.rees46-search-box__suggested .rees46-search-row {
  padding-left: 14px;
}

.rees46-search-mic {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 8;
  cursor: pointer;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23ADADBD' fill-rule='evenodd' d='M13 17.93V20h2a1 1 0 0 1 0 2H9a1 1 0 0 1 0-2h2v-2.07A7.002 7.002 0 0 1 5 11a1 1 0 0 1 2 0 5 5 0 0 0 10 0 1 1 0 0 1 2 0 7.002 7.002 0 0 1-6 6.93zM12 2a4 4 0 0 1 4 4v5a4 4 0 1 1-8 0V6a4 4 0 0 1 4-4zm0 2a2 2 0 0 0-2 2v5a2 2 0 1 0 4 0V6a2 2 0 0 0-2-2z'/%3E%3C/svg%3E");
}

.rees46-search-mic:hover {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23818192' fill-rule='evenodd' d='M13 17.93V20h2a1 1 0 0 1 0 2H9a1 1 0 0 1 0-2h2v-2.07A7.002 7.002 0 0 1 5 11a1 1 0 0 1 2 0 5 5 0 0 0 10 0 1 1 0 0 1 2 0 7.002 7.002 0 0 1-6 6.93zM12 2a4 4 0 0 1 4 4v5a4 4 0 1 1-8 0V6a4 4 0 0 1 4-4z'/%3E%3C/svg%3E");
}

.rees46-search-mic.rees46-search-mic-active {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23FB4C55' fill-rule='evenodd' d='M13 17.93V20h2a1 1 0 0 1 0 2H9a1 1 0 0 1 0-2h2v-2.07A7.002 7.002 0 0 1 5 11a1 1 0 0 1 2 0 5 5 0 0 0 10 0 1 1 0 0 1 2 0 7.002 7.002 0 0 1-6 6.93zM12 2a4 4 0 0 1 4 4v5a4 4 0 1 1-8 0V6a4 4 0 0 1 4-4z'/%3E%3C/svg%3E");
}

.rees46-search-mic.rees46-search-mic-processing {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg fill='%232F8AE3' fill-rule='evenodd'%3E%3Cpath d='M17.41 3.59a10.09 10.09 0 0 1 3.69 4.26l-2.73 1.24a7.054 7.054 0 0 0-2.59-2.98l1.63-2.52z' opacity='.1'/%3E%3Cpath d='M21.1 7.85c.8 1.74 1.07 3.67.8 5.57L18.93 13c.19-1.33 0-2.69-.56-3.91l2.73-1.24z' opacity='.2'/%3E%3Cpath d='M21.9 13.42a9.949 9.949 0 0 1-2.34 5.13l-2.27-1.97A6.938 6.938 0 0 0 18.93 13l2.97.42z' opacity='.3'/%3E%3Cpath d='M19.56 18.55a9.997 9.997 0 0 1-4.74 3.04l-.85-2.87a7.06 7.06 0 0 0 3.32-2.14l2.27 1.97z' opacity='.4'/%3E%3Cpath d='M14.82 21.59c-1.84.54-3.8.54-5.64 0l.85-2.87c1.29.38 2.65.38 3.94 0l.85 2.87z' opacity='.5'/%3E%3Cpath d='M9.18 21.59a9.997 9.997 0 0 1-4.74-3.04l2.27-1.97a7.06 7.06 0 0 0 3.32 2.14l-.85 2.87z' opacity='.6'/%3E%3Cpath d='M4.44 18.55a9.949 9.949 0 0 1-2.34-5.13L5.07 13c.19 1.33.76 2.57 1.64 3.58l-2.27 1.97z' opacity='.7'/%3E%3Cpath fill-opacity='.8' d='M2.1 13.42c-.27-1.9 0-3.83.8-5.57l2.73 1.24A7.003 7.003 0 0 0 5.07 13l-2.97.42z'/%3E%3Cpath fill-opacity='.9' d='M2.9 7.85c.8-1.74 2.08-3.22 3.69-4.26l1.63 2.52a7.054 7.054 0 0 0-2.59 2.98L2.9 7.85z'/%3E%3Cpath d='M6.59 3.59A9.97 9.97 0 0 1 12 2v3c-1.34 0-2.65.38-3.78 1.11L6.59 3.59z'/%3E%3C/g%3E%3C/svg%3E");
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
  margin-top: -12px;
}

.rees46-search__checkbox {
  margin: 0 0 5px 0;
  padding: 0;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.1px;
  font-size: 15px;
  line-height: 1.2;
  text-align: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.rees46-search__checkbox input {
  display: none;
}

.rees46-search__checkbox input + span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  color: rgba(33, 37, 41, 0.9);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 14px;
  min-width: 14px;
  height: 14px;
  margin: 2px 6px 0 0;
  background: transparent no-repeat center;
  border: 1px solid #bbb;
  text-align: center;
  border-radius: 1px;
  -webkit-transition: background .2s, border .2s;
  transition: background .2s, border .2s;
}

.rees46-search__checkbox input ~ div:after {
  content: attr(data-sup);
  vertical-align: top;
  font-size: 10.5px;
  line-height: 1;
  margin-left: 3px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  color: rgba(33, 37, 41, 0.8);
}

.rees46-search__checkbox input:checked + span:after {
  content: '';
  width: 8px;
  height: 8px;
  background: #37b24d;
  border-radius: 1px;
}

.rees46-search__checkbox input:disabled + span, .rees46-search__checkbox input:disabled ~ div {
  opacity: .5;
}

.rees46-search-filter {
  font-size: 12px;
  line-height: 2;
  color: #5a595f;
  font-weight: bold;
  text-transform: uppercase;
}

.rees46-search-hints, .rees46-search-replaces {
  margin-bottom: 10px;
}

.rees46-search-hints button, .rees46-search-replaces button {
  margin: 0 8px 8px 0;
  padding: 4px 10px;
  background-color: #FFF;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  color: #029AAD;
  font-size: 12px;
  line-height: 20px;
}

.rees46-search-replaces button {

}

/**
 * Search items
 */
.rees46-search-items {
  overflow: hidden;
}

.rees46-search-items .rees46-search-item {
  display: block;
  float: left;
  margin: 0 14px 20px 0;
  text-align: center;
  width: 178px;
  height: 320px;
  border-radius: 2px;
  background-color: #ffffff;
  border: solid 1px #f2f2f2;
  -webkit-box-sizing: border-box !important;
          box-sizing: border-box !important;
}

.rees46-search-items .rees46-search-item:hover {
  -webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #d9d9d9;
}

.rees46-search-items .rees46-search-item:hover > .rees46-search-item-action > a {
  background-color: #37b24d;
}

.rees46-search-items .rees46-search-item .rees46-search-item-photo {
  display: table;
  height: 158px;
  margin: 10px auto 6px auto;
  table-layout: fixed;
  width: 100%;
  background: #fff;
}

.rees46-search-items .rees46-search-item .rees46-search-item-photo > a {
  display: table-cell;
  height: 158px;
  vertical-align: middle;
}

.rees46-search-items .rees46-search-item .rees46-search-item-photo, .rees46-search-items .rees46-search-item .rees46-search-item-photo img {
  max-height: 158px;
  max-width: 158px;
}

.rees46-search-items .rees46-search-item .rees46-search-item-title {
  overflow: hidden;
  text-align: left;
  width: 138px;
  height: 57px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: -0.2px;
  margin: 0 10px 8px 10px;
}

.rees46-search-items .rees46-search-item .rees46-search-item-title > a {
  color: #5a595f;
  text-decoration: none;
}

.rees46-search-items .rees46-search-item .rees46-search-item-price {
  line-height: 22px;
  height: 22px;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.2px;
  text-align: right;
  color: #5a595f;
  margin: 0 10px 13px 0;
}

.rees46-search-items .rees46-search-item .rees46-search-item-action {
  display: table;
  width: 158px;
  height: 36px;
  border-radius: 2px;
  background-color: #37b24d;
  margin: 0 auto;
  -webkit-box-sizing: border-box !important;
          box-sizing: border-box !important;
}

.rees46-search-items .rees46-search-item .rees46-search-item-action > a {
  text-decoration: none;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.2px;
  text-align: center;
  color: #fff;
  display: table-cell;
  vertical-align: middle;
  border-radius: 2px;
}

.rees46-search-items .rees46-search-item .rees46-search-item-action > a:hover {
  background-color: #3fc457;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@media only screen and (min-width: 640px) {
  .rees46-search-products .rees46-search-group-description {
    max-height: 364px;
    min-height: 42px;
    overflow-y: auto;
  }
}

@media only screen and (max-width: 1280px) {
    .rees46-search-results {
        max-width: 53vw;
    }
}
@media only screen and (max-width: 1080px) {
    .rees46-search-results {
        max-width: 93.6vw;
    }
}

@media only screen and (max-width: 640px) {
  input.rees46-instant-search {
    z-index: 8;
  }
  .m-header__search__wrapper {
    z-index: 6;
  }
  .rees46-search-wrapper {
    margin-left: 0 !important;
    -ms-flex-wrap: wrap-reverse;
        flex-wrap: wrap-reverse;
    padding: 16px;
    border: none !important;
    border-radius: 0px !important;
  }
  .rees46-search-wrapper:before, .rees46-search-wrapper:after {
    left: calc(50% - 10px);
  }
  .rees46-search-product__price-old {
    font-size: 0.8125rem !important;
  }
  .rees46-search-close {
    display: none !important;
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23ADADBD' fill-rule='evenodd' d='M12 10.586l4.293-4.293a1 1 0 0 1 1.414 1.414L13.414 12l4.293 4.293a1 1 0 0 1-1.414 1.414L12 13.414l-4.293 4.293a1 1 0 1 1-1.414-1.414L10.586 12 6.293 7.707a1 1 0 0 1 1.414-1.414L12 10.586z'/%3E%3C/svg%3E") center no-repeat;
    width: 24px;
    height: 24px;
    position: absolute;
    right: 12px;
    top: 16px;
  }
  .rees46-search-results {
    max-width: 100%;
    width: 100%;
    z-index: 8;
  }
  .rees46-search-container:not(:empty), #rees46_search_products.rees46-search-container:not(:empty) {
    padding: 0;
    border-left: none;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 100%;
            flex: 1 1 100%;
  }
  .rees46-search-container:not(:empty):first-child:not(:last-child) {
    padding-bottom: 10px;
  }
  .rees46-search-container:not(:empty):first-child:not(:last-child) .rees46-search-row {
    max-width: 100%;
  }
  .rees46-search-group {
    margin: 0;
    margin-bottom: 1rem;
  }
  .rees46-search-group-description {
    padding-left: 18px;
  }
  .rees46-search-row {
    padding-right: 0;
  }
  .rees46-search-product {
    float: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    margin: 0 0 0.5rem !important;
    padding: 0;
    padding-bottom: 0.5rem !important;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .rees46-search-product__image {
    flex: 0 0 20%;
    width: 20%;
    padding-bottom: 20%;
  }
  .rees46-search-product__price {

  }
  .rees46-search-product__name-holder {
    flex: 0 0 80%;
  }
  .rees46-search-product__name {
    margin-bottom: 0.25rem !important;
    font-size: 0.8125rem !important;
  }
}
