.orderStatus {
  width: 420px;
  max-height: 95vh;
  display: flex;
  flex-direction: column;
  padding: 32px;
  overflow-y: auto;

  @media (max-width: 640px) {
    max-width: 350px;
    width: auto;
  }

  &__head {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    color: #000;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.22px;
  }

  &__desc {
    color: #999;
    font-size: 14px;
    line-height: 1;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;
  }
}

.success {
  display: flex;
  flex-direction: column;

  margin-top: 16px;

  &__round {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 56px;
    height: 56px;

    border-radius: 50%;
  }

  &__item {
    display: flex;

    padding: 16px 0;
    border-bottom: 1px solid #dee0e6;

    &:last-child {
      border-bottom: none;
    }
  }

  &__title {
    min-width: 100px;
    padding-right: 10px;

    color: #999;
    font-size: 14px;
    line-height: 1;
  }

  &__status {
    display: flex;
    flex-direction: column;

    color: #4f4f4f;
    font-size: 13px;
    line-height: 1.25;

    &>div {
      display: inline-block;
    }
  }

  &__count {
    font-size: 15px;
    line-height: 1.22;
    margin: 0 0 8px;
  }

  &__bold {
    align-items: center;
    font-weight: bold;

    &>div {
      font-size: 18px;
    }
  }
}

.closeBtn {
  display: inline-flex;
  width: 44px;
  height: 44px;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: inherit;
  border-radius: 100%;
  margin-right: -12px;

  &:hover {
    color: #202020;
    background: hsla(0, 0%, 60%, .1);
  }
}

.orangeBtn {
  width: 100%;
  margin-top: 12px;
  color: var(--color-neutrals-white);
  background-color: var(--color-brand-secondary);

  &:hover {
    background-color: var(--color-brand-secondary-shade);
  }

  &:active {
    background-color: #EB8039;
  }

  &:disabled {
    color: #202020;
    background-color: #FFC39C;
  }
}

.errorText {
  display: block;
  color: red;
  font-size: 11px;
  line-height: 1;
  margin-top: 4px;
  text-align: left;
  width: 100%;
}

.errorInput {
  background: url("data:image/svg+xml;utf8,<svg fill='rgb(248, 65, 71)' width='16' height='14' viewBox='0 0 14.56 12.98' xmlns='http://www.w3.org/2000/svg'><path d='m14.42 11.49-6.27-10.99a1 1 0 0 0 -1.74 0l-6.28 11a1 1 0 0 0 .87 1.5h12.56a1 1 0 0 0 .86-1.51zm-6.14-.49a1 1 0 1 1 -2 0v-1a1 1 0 1 1 2 0zm0-4a1 1 0 1 1 -2 0v-3a1 1 0 0 1 2 0z'/></svg>") right 12px center no-repeat #fff;
  border-color: red !important;
  padding-right: 40px;
}
