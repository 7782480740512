.video {
  padding: 32px 0;

  &_title {
    color: var(--color-Woodsmoke, #0D0E0F);
    font-size: 22px;
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.22px;
  }

  &__container {
    position: relative;
    padding-top: 55%;

    margin-top: 24px;

    &>iframe {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  &__channel {
    display: flex;
    gap: 32px;
    margin-top: 24px;
    padding: 20px 60px;
    background: rgba(2, 154, 173, 0.1);
    border-radius: 12px;

    &>div {
      display: flex;
      gap: 32px;
    }

    span {
      font-family: "Inter", sans-serif;
      font-size: 20px;
      color: var(--color-Mine-Shaft, #202020);
    }
  }
}

.orangeBtn {
  display: flex;
  align-items: center;
  color: var(--color-neutrals-white);
  background-color: var(--color-brand-secondary);

  &:hover {
    color: var(--color-neutrals-white);
    background-color: var(--color-brand-secondary-shade);
  }

  &:active {
    color: var(--color-neutrals-white);
    background-color: var(--color-brand-secondary-shade);
  }

  &:disabled {
    color: #202020;
    background-color: #FFC39C;
  }
}

@media (max-width: 768px) {
  .video {
    padding-top: 0;

    &__channel {
      flex-direction: column;
      gap: 16px;
      margin-top: 24px;
      padding: 13px;

      &>div {
        gap: 8px;
        justify-content: space-between;
      }

      img {
        height: 36px;
      }

      span {
        font-size: 12px;
      }
    }
  }

  .orangeBtn {
    justify-content: center;
    align-items: center;
    min-height: 48px;
    font-size: 16px;
  }
}
