.modalHead {
  border-bottom: none;
}

.modalBackground {
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: rgba(20, 26, 56, .6);
  z-index: 9;

  ~div {
    pointer-events: none;
  }
}
.modalNoBackground{
  background-color: transparent!important;
}
.modalBox {
  position: relative;
  display: inline-block;
  min-width: 20%;
  min-height: 100px;
  border-radius: 12px;
  background-color: white;

  @media (min-width: 1400px) {
    min-width: auto;
  }
}

.modal {
  >div {
    width: 400px;

    @media (max-width: 768px) {
      width: 90%;
      margin: auto;
    }
  }

  &_header {
    padding: 20px 12px 12px 16px;
    border-bottom: none;
    align-items: flex-start;

    >button {
      width: 12px;
      height: 12px;
      margin-right: 12px !important;
      margin-top: 2px !important;

      &:focus {
        box-shadow: none;
      }
    }

    &_center {
      position: relative;
      height: 80px;

      >div {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  &_body {
    margin: 0 24px 24px;
  }

  &_footer {
    justify-content: center;
    border-top: none;
    padding-top: 0;
    padding-bottom: 0;
  }

  &_text {
    text-align: center;
    color: #475467;
    font-size: 16px;
    line-height: 20px;

    &_center {
      font-weight: 700;
      text-align: center;
      margin-bottom: 4px;
    }
  }

  &_btn {
    max-width: 311px;
    background-color: var(--color-brand-primary);
    color: var(--color-neutrals-white);
    margin-top: 20px;

    &:hover {
      background-color: var(--color-brand-primary-shade);
    }
  }
}
