@import "global/styles/functions.scss";

.container {
  display: flex;
  align-items: flex-start;
  gap: 32px;

  @media (max-width: 1280px) {
    gap: getVwD(32px);
  }

  @media (max-width: 1080px) {
    flex-direction: column;
    gap: 24px;
    padding: 16px;
  }

  @media (max-width: 768px) {
    gap: 6.4vw;
    padding: 0;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: var(--color-neutrals-white);
  border-radius: 8px;
  width: 60%;
  padding: 32px;

  @media (max-width: 1280px) {
    gap: getVwD(8px);
    padding: getVwD(32px);
  }

  @media (max-width: 1080px) {
    width: 100%;
    padding: 24px 10px;
  }

  @media (max-width: 768px) {
    gap: 2.13vw;
    padding: 6.4vw 2.67vw;
  }
}

.title {
  display: block;
  color: #262626;
  font-size: 28px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 8px;

  @media (max-width: 1280px) {
    font-size: getVwD(28px);
    line-height: getVwD(24px);
    margin-bottom: getVwD(8px);
  }

  @media (max-width: 768px) {
    font-size: 5.87vw;
    line-height: normal;
    margin-bottom: 1vw;
  }
}

.subtitle {
  color: #262626;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  @media (max-width: 1280px) {
    font-size: getVwD(16px);
    line-height: getVwD(24px);
  }

  @media (max-width: 768px) {
    font-size: 3.2vw;
    line-height: normal;
  }
}

.wrapperInput {
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.text {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: var(--color-location);
  margin-top: 8px;

  @media (max-width: 1280px) {
    font-size: getVwD(12px);
    line-height: getVwD(15px);
    margin-top: getVwD(8px);
  }

  @media (max-width: 768px) {
    font-size: 2.67vw;
    line-height: normal;
    margin-bottom: 0;
  }
}

.link {
  color: var(--color-brand-primary-shade);
  cursor: pointer;

  &:hover {
    color: var(--color-brand-secondary);
  }
}

.linkText {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: var(--color-location);
  cursor: pointer;

  &:hover {
    color: var(--color-brand-secondary);
  }

  @media (max-width: 1280px) {
    font-size: getVwD(12px);
    line-height: getVwD(15px);
  }

  @media (max-width: 768px) {
    font-size: 2.67vw;
    line-height: normal;
  }
}

.timerText {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: var(--color-location);

  @media (max-width: 1280px) {
    font-size: getVwD(12px);
    line-height: getVwD(15px);
  }

  @media (max-width: 768px) {
    font-size: 2.67vw;
    line-height: normal;
  }
}

.button {
  display: none;

  &:hover {
    background-color: var(--color-brand-secondary-shade);
    color: var(--color-neutrals-white);
  }

  @media (max-width: 768px) {
    display: block;
    width: 100%;
    height: 13vw;
    margin-top: 4vw;
    padding: 3.47vw;
    background: var(--color-brand-secondary);
    color: var(--color-neutrals-white);
    font-size: 4.27vw;
  }
}

.backBtn {
  display: none;

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 13.3vw;
    padding: 2.67vw 4.27vw;
    margin-top: 2.13vw;
    border: 1px solid var(--color-location);
    border-radius: 8px;
    color: #4F4F4F;
    font-size: 4.27vw;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;

    &:hover {
      color: #4F4F4F;
    }
  }
}

.errorText {
  display: block;
  color: red;
  font-size: 11px;
  line-height: 1;
  margin-top: 4px;
  text-align: left;
  width: 100%;
}

.errorInput {
  background: url("data:image/svg+xml;utf8,<svg fill='rgb(248, 65, 71)' width='16' height='14' viewBox='0 0 14.56 12.98' xmlns='http://www.w3.org/2000/svg'><path d='m14.42 11.49-6.27-10.99a1 1 0 0 0 -1.74 0l-6.28 11a1 1 0 0 0 .87 1.5h12.56a1 1 0 0 0 .86-1.51zm-6.14-.49a1 1 0 1 1 -2 0v-1a1 1 0 1 1 2 0zm0-4a1 1 0 1 1 -2 0v-3a1 1 0 0 1 2 0z'/></svg>") right 12px center no-repeat #fff;
  border-color: red !important;
  padding-right: 40px;
}

.modal {
  max-width: 1084px;
  padding: 40px;

  @media (max-width: 768px) {
    padding: 16px;
  }

  &__text {
    max-height: 400px;
    overflow-y: auto;
    font-size: 14px;
  }

  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin: 17px 0;

    @media (max-width: 1080px) {
      flex-wrap: wrap;
    }

    @media (max-width: 768px) {
      gap: 8px;
    }
  }

  &__btn {
    display: block;
    margin: 0 auto;
    width: 180px;
    background-color: var(--color-brand-secondary);
    color: var(--color-neutrals-white);

    &:hover {
      background-color: var(--color-brand-secondary-shade);
    }
  }
}

.banks {
  &__image {
    min-width: 50px;
    min-height: 50px;
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
  }
}
