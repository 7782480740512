@import url("_functions.scss");

// Headings
.page-title {
  font-size: 28px;
  font-weight: 700;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 32px;
  text-align: center;
  &.text-left {
    text-align: left;
  }
}
.page-subtitle {
  font-size: 22px;
  font-weight: 700;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 24px;
}
.widget-title, .module-title {
  font-size: 22px;
  font-weight: 700;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 24px;
}

// Content from wysiwyg editors
.wysiwyg-content {
  font-size: 16px;
  p {
    line-height: 1.5;
  }
  a {
    text-decoration: none;
    color: var(--color-brand-primary);
    &:hover {
      color: var(--color-brand-secondary);
    }
    &:visited {
      color: var(--color-labels-blue);
    }
  }
}

// Desktop
@media (max-width: 1280px) {
  .page-title {
    font-size: getVwD(28px);
    margin-bottom: getVwD(32px);
  }
  .page-subtitle {
    font-size: getVwD(22px);
    margin-bottom: getVwD(24px);
  }
  .widget-title, .module-title {
    font-size: getVwD(22px);
    margin-bottom: getVwD(24px);
  }

  .wysiwyg-content {
    font-size: getVwD(16px);
  }
}

// Mobile
@media (max-width: 768px) {
  .page-title {
    font-size: getVwM(24px);
    margin-bottom: getVwM(24px);
  }
  .page-subtitle {
    font-size: getVwM(16px);
    margin-bottom: getVwM(16px);
  }
  .widget-title, .module-title {
    font-size: getVwM(16px);
    margin-bottom: getVwM(16px);
  }

  .wysiwyg-content {
    font-size: getVwM(12px);
  }
}


.txt-bold-1{
    font-style: normal;
    font-weight: 800;
    font-size: 28.5px;
    line-height: 28px;
}
.txt-bold-2{
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 72px;
}
.txt-bold-3{
    font-style: normal;
    font-weight: 800;
    font-size: 28.5px;
    line-height: 64px;
}
.txt-bold-4{
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
}
.txt-bold-5{
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
}
.txt-bold-6{
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
}
.txt-bold-7{
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
}
.txt-body-1{
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}
.txt-body-2{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
}
.txt-body-3{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
}
.txt-body-4{
    font-style: normal;
    font-weight: 400;
    font-size: 13.6px;
    line-height: 22px;
}
.truncate p {
    margin: 0; /* Убираем внешний отступ */
    -webkit-line-clamp: 1; /* Число отображаемых строк */
    display: -webkit-box; /* Включаем флексбоксы */
    -webkit-box-orient: vertical; /* Вертикальная ориентация */
    overflow: hidden; /* Обрезаем всё за пределами блока */
}
