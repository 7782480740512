.wrapper {
    padding: 10px 0;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 199px;
    height: 40px;
    background: var(--color-brand-primary-shade);
    border-radius: 8px;
    padding: 12px;
    &>span {
        font-size: 16px;
        font-weight: 500;
        line-height: 17px;
        color: var(--color-neutrals-white);
    }
    &:hover{
      background: var(--color-brand-primary);
    }
    &:hover>.catalogMenu {
        display: block;
    }
    @media(max-width: 1200px) {
        width: 168px;
        height: 34px;
        span {
            font-size: 12px;
        }
    }
}

.divider {
    width: 21px;
    height: 1px;
    transform: rotate(-81.87deg);
    background: #e5e5e5;
    @media(max-width: 1400px) {
        width: 18px;
    }
    @media(max-width: 1200px) {
        width: 16px;
    }
}

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.container {
    position: relative;
    display: flex;
    justify-content: space-between;
}

.user {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    span {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #5A595F;
    }
    &:hover {
        span {
            color: var(--color-brand-secondary);
        }
    }
    @media(max-width: 1200px) {
        gap: 4px;
        svg {
            width: 20px;
            height: 20px;
        }
        span {
            font-size: 12px;
        }
    }
}

.catalogMenu {
    display: none;
    position: absolute;
    top: 72%;
    left: 12px;
    padding-top: 20px;
    z-index: 4;
    text-align: start;
}

.list {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    gap: 3px;
}
