nav.navbar {
  background: var(--color-brand-primary-shade);
  padding: 11px 0 !important;
  z-index: 5;
}

nav.navbar.sticky {
  z-index: 5;
}

.wrapperIcon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 24px;

  @media(max-width: 1200px) {
    svg {
      width: 30px;
      height: 30px;
    }
  }
}

.container {
  position: relative;
}

.catalogBtn {
  margin-left: 36px;
  transition: transform .5s ease;

  @media(max-width: 1400px) {
    margin-left: 22px;
  }

  @media(max-width: 1200px) {
    width: 35px;
    margin-left: 15px;
  }
}

.addBtn {
  transform: rotate(1turn) scale(1);
  cursor: pointer;
}

.removeBtn {
  transform: rotate(180deg) scale(0);
}

.logoContainer {
  display: flex;
  align-items: center;
  flex: 1;
}

.searchContainer {
  flex: 2;
}

.mainIcon {
  @media(max-width: 1200px) {
    width: 168px;
  }
}

.catalogContainer {
  position: relative;

  &:hover {
    &>.catalogMenu {
      display: block;
    }
  }
}

.catalogMenu {
  display: none;
  position: absolute;
  top: 36px;
  left: -200px;
  padding-top: 20px;
}

.cartPopup {
  display: flex;
  align-items: center;
  justify-content: center;

  >a>div {
    margin-right: 10px;
  }
}

.rees46-search-results {
  max-width: inherit;
}

.user__icon {
  &:hover {
    filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.25));
    cursor: pointer;
  }
}
