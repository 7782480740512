@import "global/styles/functions.scss";

#switch {
  width: 40px;
  height: 20px;
  border: none;
  background-color: var(--color-location);
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e');
  cursor: pointer;

  &:checked {
    border: none;
    background-color: var(--color-brand-primary-shade);
  }

  @media (max-width: 1280px) {
    width: getVwD(44px);
    height: getVwD(24px);
  }

  @media (max-width: 768px) {
    width: 11vw;
    height: 6vw;
  }
}
