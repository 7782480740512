.cities {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 300px;
  max-height: 600px;
  padding: 10px 22px;
  padding-top: 30px;
  overflow-y: auto;
  overflow-x: hidden;

  &__header {
    width: 100%;
    margin-bottom: 14px;
    padding-bottom: 12px;
    border-bottom: 1px solid #e0e0e0;

    text-align: center;
    font-weight: 700;
    font-size: 18px;
  }

  &__columns {
    display: flex;
    gap: 8px;

    &_load {
      width: 100%;
    }
  }

  &__column {
    width: minmax(120px, 200px);
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__item {
    cursor: pointer;

    input {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    input:checked~span {
      color: #202020;
      font-weight: 700;

      &:before {
        border-width: 2px;
        border-color: #fb791b;
        background: #fb791b;
        box-shadow: inset 0 0 0 2px #fff;
      }
    }

    span {
      align-items: center;
      color: #4f4f4f;
      display: flex;
      font-size: 15px;
      line-height: 1.2;

      &:before {
        border-radius: 50%;
        border: 1px solid #bdbdbd;
        content: "";
        flex-shrink: 0;
        margin-right: 14px;
        height: 14px;
        width: 14px;
      }
    }

    &:hover span {
      color: #fb791b;

      &:before {
        border-color: #fb791b;
      }
    }
  }

  &__close {
    width: 14px;
    height: 14px;
  }

  &__notice {
    max-width: 300px;
    margin-top: 20px;

    color: #999;
    font-size: 12px;
    line-height: 1.25;
  }
}

.closeBtn {
  position: absolute;
  top: 16px;
  right: 16px;
  display: inline-flex;
  width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: inherit;
  border-radius: 100%;

  &:hover {
    color: #202020;
    background: hsla(0, 0%, 60%, .1);
  }
}

.agreeButton {
  background-color: var(--color-brand-secondary);
  font-size: 16px;
  line-height: 26px;
  color: var(--color-neutrals-white);
  margin-top: 20px;

  &:hover {
    background-color: var(--color-brand-secondary-shade);
  }

  @media (max-width: 1280px) {
    font-size: getVwD(16px);
    line-height: getVwD(26px);
  }
}
