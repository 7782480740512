:root {
  --color-brand-primary: #00BCCC;
  --color-brand-primary-shade: #029AAD;
  --color-brand-secondary: #FB791B;
  --color-brand-secondary-shade: #FF5A00;
  --color-brand-Peach-Orange: #FFC39C;
  --color-neutrals-white: #FFFFFF;
  --color-neutrals-black: #000000;
  --color-neutrals-border-solid: #E0E0E0;
  --color-neutrals-grey: #F2F2F2;
  --color-neutrals-dark-grey: #333333;
  --color-neutrals-turquoise: #EAF4F6;
  --color-neutrals-background: #EAEAEA;
  --color-labels-blue: #009DDA;
  --color-labels-light-blue: rgba(0, 188, 204, 0.3);
  --color-labels-red: #FF3B30;
  --color-labels-yellow: #FFCD00;
  --color-labels-orange: #FB791B;
  --color-upper-header: #F7F7F7;
  --color-location: #999999;
  --color-checkmark: #AEAEB2;
  --color-Mine-Shaft: #202020;
  --color-silver: #c7c7c7;
  --color-dark-Silver: #C4C4C4;
  --color-Mischka-light: #DDDDE3;
  --color-Mischka: #DDE0E5;
  --color-French-Gray: #C3C2CA;
  --color-Jumbo: #808089;
  --color-Emperor: #4F4F4F;
  --color-Shark: #27272A;
  --color-Dark-Tuna: #393948;
  --color-Tuna: #3C3C43;
  --color-LightSilver: #C4C4C4;
  --color-Woodsmoke: #0D0E0F;
  --color-Marigold-Yellow: #FBDE77;
  --color-Energy-Yellow: #F8D548;
  --color-Wild-Sand: #F5F5F5;
  --color-Tundora: #424242;
  --color-Ship-Gray: #49454F;
  --color-River-Bed: #4B5563;
  --color-Baby-Blue: #E2FDFF;
  --color-Black-Squeeze: #DDEEF2;
  --color-Galleryapprox: #f0f0f0;
  --color-Gray: #7f7f7f;
  --color--Midnight: #001529;
  --color--Gray15: #262626;
  --color-white: white;
  --color-orange: #fb791b;
  --color-light-yellow: #F8D5481A;
  --color-grey-200: #999;
  --color-grey-600: #4f4f4f;
  --color-grey-900: #202020;
  --color-grey-100: #e0e0e0;
}

[data-channel='samsung'] {
  --color-brand-primary: #2c2c2c;
  --color-brand-primary-shade: #121212;
  --color-brand-secondary: #2189ff;
  --color-brand-secondary-shade: #006bea;
  --color-brand-Peach-Orange: #FFC39C;
  --color-neutrals-white: #FFFFFF;
  --color-neutrals-black: #000000;
  --color-neutrals-border-solid: #E0E0E0;
  --color-neutrals-grey: #F2F2F2;
  --color-neutrals-dark-grey: #333333;
  --color-neutrals-turquoise: #EAF4F6;
  --color-neutrals-background: #EAEAEA;
  --color-labels-blue: #009DDA;
  --color-labels-light-blue: rgba(0, 188, 204, 0.3);
  --color-labels-red: #FF3B30;
  --color-labels-yellow: #FFCD00;
  --color-labels-orange: #FB791B;
  --color-upper-header: #F7F7F7;
  --color-location: #999999;
  --color-checkmark: #AEAEB2;
  --color-Mine-Shaft: #202020;
  --color-silver: #c7c7c7;
  --color-dark-Silver: #C4C4C4;
  --color-Mischka-light: #DDDDE3;
  --color-Mischka: #DDE0E5;
  --color-French-Gray: #C3C2CA;
  --color-Jumbo: #808089;
  --color-Emperor: #4F4F4F;
  --color-Shark: #27272A;
  --color-Dark-Tuna: #393948;
  --color-Tuna: #3C3C43;
  --color-LightSilver: #C4C4C4;
  --color-Woodsmoke: #0D0E0F;
  --color-Marigold-Yellow: #FBDE77;
  --color-Energy-Yellow: #F8D548;
  --color-Wild-Sand: #F5F5F5;
  --color-Tundora: #424242;
  --color-Ship-Gray: #49454F;
  --color-River-Bed: #4B5563;
  --color-Baby-Blue: #E2FDFF;
  --color-Black-Squeeze: #DDEEF2;
  --color-Galleryapprox: #f0f0f0;
  --color-Gray: #7f7f7f;
  --color--Midnight: #001529;
}

.brand-primary{
  color: var(--color-brand-primary) !important;
}
.bg-brand-primary{
  background-color: var(--color-brand-primary) !important;
}
.brand-primary-shade{
  color: var(--color-brand-primary-shade) !important;
}
.bg-brand-primary-shade{
  background-color: var(--color-brand-primary-shade) !important;
}
.brand-secondary{
  color: var(--color-brand-secondary) !important;
}
.bg-brand-secondary{
  background-color: var(--color-brand-secondary) !important;
}
.brand-secondary-shade{
  color: var(--color-brand-secondary-shade) !important;
}
.bg-brand-secondary-shade{
  background-color: var(--color-brand-secondary-shade) !important;
}

.neutrals-white{
  color: var(--color-neutrals-white) !important;
}
.bg-neutrals-white{
  background-color: var(--color-neutrals-white) !important;
}
.neutrals-border-solid{
  color: var(--color-neutrals-border-solid) !important;
}
.bg-neutrals-border-solid{
  background-color:var(--color-neutrals-border-solid) !important;
}
.neutrals-grey{
  color: var(--color-neutrals-grey) !important;
}
.bg-neutrals-grey{
  background-color: var(--color-neutrals-grey) !important;
}
.neutrals-turquoise{
  color: var(--color-neutrals-turquoise) !important;
}
.bg-neutrals-turquoise{
  background-color: var(--color-neutrals-turquoise) !important;
}
.neutrals-background{
  color: var(--color-neutrals-background) !important;
}
.bg-neutrals-background{
  background-color: var(--color-neutrals-background) !important;
}
.labels-blue{
  color: var(--color-labels-blue) !important;
}
.bg-labels-blue{
  background-color: var(--color-labels-blue) !important;
}
.labels-light-blue{
  color: var(--color-labels-light-blue) !important;
}
.bg-labels-light-blue{
  background-color: var(--color-labels-light-blue) !important;
}
.labels-red{
  color: var(--color-labels-red) !important;
}
.bg-labels-red{
  background: var(--color-labels-red)  !important;
}
.labels-yellow{
  color: var(--color-labels-yellow) !important;
}
.bg-labels-yellow{
  background-color: var(--color-labels-yellow) !important;
}
.labels-orange{
  color: var(--color-labels-orange) !important;
}
.bg-labels-orange{
  background-color:var(--color-labels-orange) !important;
}
.bg-upper-header{
  background-color: var(--color-upper-header) !important;
}
.location-color{
  color: var(--color-location);
}
