.errorText {
  display: block;
  color: red;
  font-size: 11px;
  line-height: 1;
  margin-top: 4px;
  text-align: left;
  width: 100%;
}

.errorInput {
  background: url("data:image/svg+xml;utf8,<svg fill='rgb(248, 65, 71)' width='16' height='14' viewBox='0 0 14.56 12.98' xmlns='http://www.w3.org/2000/svg'><path d='m14.42 11.49-6.27-10.99a1 1 0 0 0 -1.74 0l-6.28 11a1 1 0 0 0 .87 1.5h12.56a1 1 0 0 0 .86-1.51zm-6.14-.49a1 1 0 1 1 -2 0v-1a1 1 0 1 1 2 0zm0-4a1 1 0 1 1 -2 0v-3a1 1 0 0 1 2 0z'/></svg>") right 12px center no-repeat #fff;
  border-color: red !important;
  padding-right: 40px;
}

.checkboxWrapper {
  display: flex;
  gap: 16px;
  margin: 16px 0;

  >div {
    >label {
      font-weight: 700;
      font-size: 15px;
      line-height: 16px;
      color: #000000;
      cursor: pointer;

      @media (max-width: 640px) {
        font-size: 4vw;
        line-height: 4.27vw;
      }
    }

    >input {
      cursor: pointer;
    }
  }

}

.link {
  display: inline-block;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: var(--color-brand-primary-shade);
  text-decoration: none;
  transition: color .2s ease;

  &:hover {
    color: var(--color-brand-secondary-shade);
  }
}
.input__wrapper {
  position: relative;
  .item {
    margin-bottom: 16px;
  }
  .input {
    width: 283px;
    height: 56px;
    border: 1px solid #E0E0E0;
    border-radius: 8px;

    @media (max-width: 1080px) {
      width: 100%;
      height: 50px;
    }

    @media (max-width: 640px) {
      height: 13.33vw;
    }

    &__wrapper {
      position: relative;
    }

    &__icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 8px;
      cursor: pointer;
      background: transparent;
      border: none;

      &:hover {
        >svg {
          >path {
            fill: var(--color-brand-secondary);
          }
        }
      }
    }
  }
}

.modalBodyWrap {
  width: 420px;
  max-height: 95vh;
  display: flex;
  flex-direction: column;
  padding: 32px;
  overflow-y: auto;

  &__head {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    color: #000;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.22px;
    margin-bottom: 0;
  }

  form {
    margin-top: 16px;
  }

  @media (max-width: 768px) {
    gap: 16px;
    width: 80vw;
    padding: 32px;
  }

  @media (max-width: 640px) {
    gap: 4px;
    width: 80vw;
    min-width: calc(280px - 32px);
    padding: 16px;
  }

  .input__wrapper {
    button {
      margin-top: 16px;
    }
    .input {
      width: 100%;
    }
  }
}

.closeBtn {
  display: inline-flex;
  width: 44px;
  height: 44px;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: inherit;
  border-radius: 100%;
  margin-right: -12px;

  &:hover {
    color: #202020;
    background: hsla(0, 0%, 60%, .1);
  }
}
