.navbarContainer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  min-width: 100%;
  background: var(--color-neutrals-white);
  border-top: 1px solid #dee0e6;
  z-index: 11;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 0;
  text-align: center;
  flex: 1 1 auto;
  text-decoration: none;
  cursor: pointer;
  border: none;
  background-color: inherit;
}

.imageWrapper {
  position: relative;
}

.icon {
  width: 28px;
  height: 28px;

  @media (max-width: 640px) {
    width: 6.66vw;
    height: 6.66vw;
  }
}

.title {
  color: #808080;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;

  @media (max-width: 640px) {
    font-size: 3.2vw;
  }
}

.cartCount {
  position: absolute;
  right: -3px;
  top: 2px;
  min-width: 12px;
  min-height: 12px;
  color: var(--color-neutrals-white);
  background: var(--color-brand-secondary);
  border-radius: 50%;
  font-weight: 700;
  font-size: 8px;
  line-height: 12px;
  text-align: center;
  z-index: 1;

  @media (max-width: 640px) {
    right: -1vw;
    top: 0vw;
    min-width: 3.2vw;
    min-height: 3.2vw;
    font-size: 2.13vw;
    line-height: 3.13vw;
  }
}

.contactWrapper {

  &:active,
  &:first-child:active,
  &[aria-describedby] {
    svg>path {
      stroke: var(--color-brand-secondary);
    }
  }
}

.triggerWrapper {

  &:active,
  &:first-child:active,
  &[aria-describedby] {
    svg>path {
      fill: var(--color-brand-secondary);
    }
  }
}

.active {
  >ellipse {
    fill: var(--color-brand-secondary);
  }

  >circle {
    fill: var(--color-brand-secondary);
  }

  >g>path {
    fill: var(--color-brand-secondary);
  }
}

.modal {
  &__auth {
    z-index: 10;
  }
}
