$snow-size: 17px;
$snow-blur: 0;
$snow-left: 0;
$snow-opacity: 1;
$snow-animation-duration: 10s;
$snow-animation-delay: 0s;

@keyframes snowfall {
  from {
    transform: translate3d(var(--sw-tx, 0), var(--sw-ty, -10vh), 0) rotate(0deg);
  }
  34% {
    transform: translate3d(var(--sw25-tx, 0), var(--sw25-ty, 20vh), 0) rotate(360deg);
  }
  67% {
    transform: translate3d(var(--sw75-tx, 0), var(--sw75-ty, 60vh), 0) rotate(720deg);
  }
  to {
    transform: translate3d(var(--sw100-tx, 0), var(--sw100-ty, 100vh), 0) rotate(1080deg);
  }
}

.snow {
  width: var(--sw-s, $snow-size);
  height: var(--sw-s, $snow-size);
  filter: blur(var(--sw-f, $snow-blur));
  left: var(--sw-l, $snow-left);
  transform: translate3d(0, -10vh, 0) rotate(0deg);
  animation: snowfall var(--sw-t, $snow-animation-duration) var(--sw-d, $snow-animation-delay) infinite linear;
  opacity: var(--sw-o, $snow-opacity);
  position: absolute;
  border-radius: 50%;
}

.snow-container {
  overflow: hidden;
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  z-index: 9999;
}
.snowflake-icon {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.snowflake-icon svg {
  width: 100%;
  height: 100%;
  filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.3));
}