.tab {
  flex: content;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  height: 50px;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  cursor: pointer;

  border-bottom: 1px solid rgba(31, 31, 31, 0.12);
  background-color: var(--color-upper-header);
  color: var(--color-neutrals-black);

  &::after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: var(--color-brand-secondary-shade);
    position: absolute;
    bottom: 0;
    transform: scaleX(0);
    transform-origin: top right;
    transition: transform .5s;
  }

  &:nth-child(2n) {
    color: var(--color-Tuna);
  }

  &:first-child {
    border-radius: 4px 0px 0px 0px;
  }

  &:last-child {
    border-radius: 0px 4px 0px 0px;
  }

  &:hover {
    color: var(--color-brand-secondary-shade);
  }
}

.active {
  background-color: var(--color-neutrals-white);
  color: var(--color-brand-secondary-shade) !important;
  pointer-events: none;

  &::after {
    transform: scaleX(1);
    transform-origin: top left;
  }
}
