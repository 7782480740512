.wrapper {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: var(--color-neutrals-white);
  height: 50px;
  padding: 10px 16px;
  border-bottom: 1px solid var(--color-neutrals-white);

  &__header {
    @media (max-width: 1080px) {
      z-index: 6;
    }
  }

  @media (max-width: 640px) {
    height: 13.33vw;
    padding: 2.67vw 4.27vw;
  }
}

.icon {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 640px) {
    > a > svg {
      width: 21.87vw;
      height: 4vw;
    }
  }
}

.title {
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: -0.04em;
  color: var(--color-location);

  @media (max-width: 640px) {
    font-size: 4.27vw;
    width: 70%;
  }

  &_load {
    width: 60px;
  }
}

.locationWrapper {
  display: flex;
  align-items: center;
}

.locationIcon {
  width: 24px;
  height: 24px;
  margin-right: 3px;

  @media (max-width: 640px) {
    width: 6.2vw;
    height: 6.2vw;
    margin-right: 1vw;
  }
}

.button {
  border: none;
  background: 0 0;
  padding: 0;

  @media (max-width: 640px) {
    > svg {
      width: 6.2vw;
      height: 6.2vw;
    }
  }
}

.navbarOverlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 85%;
  height: 100%;
  background: var(--color-neutrals-white);
  z-index: 12;
  transform: translateX(100%);
  transition: all 0.3s;
  padding: 32px;
}

.navbarFade {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 11;
  display: none;
  opacity: 0;
  transition: all 0.3s;
}

.active {
  display: block;
  opacity: 1;
  transform: translateX(0%);
}

.closeIcon {
  margin-right: -8px;
  cursor: pointer;
}

.langWrapper {
  display: flex;
  gap: 12px;
}

.langTitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 10px;
}

.langActive {
  color: var(--color-brand-secondary-shade);
}

.langLink {
  color: var(--color-brand-primary-shade);
  text-decoration: none;

  &:hover {
    color: var(--color-brand-secondary-shade);
  }
}

.divider {
  border-bottom: 1px solid var(--color-neutrals-border-solid);
  margin-bottom: 25px;
}

.block {
  margin: 25px 0;
}

.subTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: #5a595f;
}

.menuItems {
  text-decoration: none;
}

.menuWrapper {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.menuIcon {
  margin-right: 10px;
  height: 6vw;
  width: 6vw;
}

.langSwitcher {
  display: inline-flex;
  gap: 12px;

  & > button {
    font-size: 20px;
    font-family: Inter;
    font-weight: 500;
    padding: 0;
    border: none;
    background: none;
    outline: none;
    color: var(--color-brand-primary-shade);

    &:active {
      color: var(--color-brand-secondary-shade);
      border-color: none !important;
      background: none !important;
    }

    &:hover {
      color: var(--color-brand-secondary-shade);
      background: none;
    }
  }
}

.langSwitcherActive {
  color: var(--color-brand-secondary-shade) !important;
}

.langSwitcherWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.arrow {
  @media (max-width: 640px) {
    width: 2.67vw;
    height: 4.27vw;
  }
}

.search_icon {
  fill: var(--color-brand-primary-shade);
  margin-right: 16px;
  animation: animation 0.2s ease-out;

  @media (max-width: 640px) {
    width: 6.2vw;
    height: 6.2vw;
  }
}

.search {
  &__wrapper {
    padding: 12px 16px;
    border-bottom: 1px solid #e3e3e3;
  }

  &__input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    margin: 4px 16px 13px;
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    height: 44px;

    > svg {
      fill: #949494;
    }
  }

  &__text {
    font-size: 18px;
    color: #686868;

    @media (max-width: 640px) {
      font-size: 3.73vw;
      line-height: 4.85vw;
    }
  }
}

.modal {
  &__search {
    z-index: 10;
  }

  &__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    background-color: var(--color-neutrals-white) !important;
    z-index: 6;
  }
}

.border_btm {
  border-bottom: 1px solid #e3e3e3;
}

.hidden {
  visibility: hidden;
  height: 0;
}

@keyframes animation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
