.sliderWrapper {
  position: relative;
  height: auto;
  padding: 1px 0 0;
}

.sliderSlide {
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: 0;
  box-sizing: border-box;
  // margin-top: 56px;
  overflow: hidden;
  /* Ensure the overflow is hidden to prevent the image from overflowing the container */
  cursor: pointer;
  transition: transform 0.3s ease;
  /* Add a smooth transition for the zoom effect */

  &>img {
    pointer-events: none;
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    transition: transform 0.3s ease;
    /* Add the same transition for the image */
  }

  &:hover {
    transform: scale(1.2);
    /* Adjust the scale factor as needed */

    @media (max-width: 1080px) {
      transform: none;
    }

    &>img {
      opacity: 0;

      @media (max-width: 1080px) {
        opacity: 1;
      }
    }
  }
}

.sliderContainer {
  max-width: 321px;

  @media (max-width:768px) {
    max-width: 80vw;
    height: 90vw;
  }

  @media (max-width: 480px) {
    max-width: 100%;
  }
}

.thumbsContainer {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 346px;
  margin-top: 24px;
  padding: 0 28px;
}

.zoomSlide {
  cursor: pointer;
  height: 320px;

  &:hover {
    background-size: 120%;

    &>img {
      opacity: 0;
    }
  }
}

.prev,
.next {
  position: absolute;
  cursor: pointer;
  height: 48px;
  display: inline-flex;
  align-items: center;

  &:hover {
    background-color: inherit !important;
    box-shadow: none !important;
  }

  &>svg {
    fill: var(--color-brand-secondary-shade)
  }

  @media (max-width: 768px) {
    display: none;
    top: 0;
    bottom: 0;
    margin: auto 0;
    z-index: 2;

    &>svg {
      fill: var(--color-brand-primary-shade);
      width: 50px;
      height: 50px;
    }
  }
}

.prev {
  left: 0;
}

.next {
  right: 0;
}

.thumbsDisabledBtn {
  opacity: .6;
}

.thumbsImg {
  height: 48px;
}

.thumbSlide {
  display: inline-block;
  width: 48px;
  height: 48px;
  padding: 4px;
  cursor: pointer;
  border: 1px solid #00000000;
  border-radius: 3px;

  &>img {
    height: 100%;
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
  }
}

.thumbsActive {
  border: 1px solid var(--color-brand-secondary, #FB791B);
}

.bullet {
  display: inline-block;
  width: 7px;
  height: 7px;

  margin: 0 6px;
  border-radius: 100%;
  background-color: #C4C4C4;

  &_Active {
    background-color: var(--color-brand-secondary, #FB791B);
  }

  &_Horisontal {
    bottom: -4px !important;
  }
}

.sliderModal {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 70vw;
  height: 80vh;

  @media (min-width: 1600px) {
    max-width: 50vw;
    height: 60vh;
  }

  &__head {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
    display: flex;
    justify-content: flex-end;
  }
}

.closeBtn {
  display: inline-flex;
  width: 44px;
  height: 44px;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: inherit;
  border-radius: 100%;

  &:hover {
    color: #202020;
    background: hsla(0, 0%, 60%, .1);
  }
}

@media(max-width: 768px) {
  .sliderSlide {
    height: 100%;
    
    &>img {
      margin: 0 auto;
    }
  }
}
