.input {
  &_code {
    width: 56px;
    height: 56px;
    padding: 15px 20px 15px 20px;
    border-radius: 15px;
    background-color: #EDEDED;
    box-shadow: 0px 0px 2px 0px #00000033 inset;
    font-size: 25px;
    font-weight: 500;
    line-height: 30.26px;
    outline: none;
    border: none;

    &:focus {
      outline: auto;
    }
  }

  &_wrap {
    display: flex;
    justify-content: center;
    gap: 16px;
  }
}
