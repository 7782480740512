@import "global/styles/functions.scss";

.container {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: inherit;
  color: var(--color-brand-primary-shade);
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background: #F6F7F8;
  padding: 0;
  font-size: 18px;
  font-weight: 600;
  outline: none;

  &:hover {
    background-color: var(--color-brand-primary);
    color: var(--color-neutrals-white);

    &:first-child {
      >svg>path {
        stroke: var(--color-neutrals-white);
      }
    }

    &:last-child {
      >svg>path {
        fill: var(--color-neutrals-white);
      }
    }
  }

  &[disabled] {
    color: var(--color-location);
    pointer-events: none;

    >svg>path {
      fill: var(--color-dark-Silver);
    }
  }

  @media (max-width: 1280px) {
    width: getVwD(24px);
    height: getVwD(24px);
    font-size: getVwD(18px);
  }

  @media (max-width: 768px) {
    width: 6.4vw;
    height: 6.4vw;
    font-size: 4.8vw;
  }
}

.icon {
  width: 18px;
  height: 18px;

  @media (max-width: 1280px) {
    width: getVwD(18px);
    height: getVwD(18px);
  }

  @media (max-width: 768px) {
    width: 4.8vw;
    height: 4.8vw;
  }
}

.count {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  color: #262626;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;

  @media (max-width: 1280px) {
    width: getVwD(24px);
    height: getVwD(24px);
    font-size: getVwD(16px);
  }

  @media (max-width: 768px) {
    width: 6.4vw;
    height: 6.4vw;
    font-size: 3.2vw;
  }
}

.disabled {
  color: #999999;
}

.modalContainer {
  @media (max-width: 768px) {
    top: -20%;
  }
}

.modalTitle {
  padding: 16px 24px;
  text-align: center;
  font-size: 22px;
  line-height: normal;
  font-weight: 600;
  color: #313234;

  @media (max-width: 768px) {
    padding: 11px 24px;
    font-size: 14px;
    font-weight: 400;
  }
}

.modalSubtitle {
  padding: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  cursor: pointer;

  @media (max-width: 768px) {
    padding: 10px 16px;
    font-size: 12px;
    line-height: 15px;
  }
}

.favourites {
  color: var(--color-brand-primary-shade);
  border-bottom: 2px solid var(--color-upper-header);

  &:hover {
    color: var(--color-brand-secondary);
  }
}

.deleteTitle {
  color: #FF3B30;
  border-bottom: 2px solid var(--color-upper-header);

  &:hover {
    color: var(--color-brand-secondary);
  }
}

.backTitle {
  color: var(--color-Woodsmoke);
  padding-bottom: 24px;

  @media(max-width: 768px) {
    padding-bottom: 16px;
  }

  &:hover {
    color: var(--color-brand-secondary);
  }
}

.delete {
  &_btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: inherit;
    color: var(--color-brand-primary-shade);
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background: #F6F7F8;
    padding: 0;
    font-size: 18px;
    font-weight: 600;
    outline: none;
  }

  &_icon {
    min-width: 16px;
    cursor: pointer;

    &:hover {
      >path {
        stroke: var(--color-brand-secondary);
      }
    }
  }
}
