@use "sass:list";

.vertical {
  display: flex;
  flex-direction: column;
  visibility: hidden;

  a {
    width: 100%;
    height: 100%;
    text-decoration: none;
    padding-top: 9px;
  }

  [data-tooltip]:before {
    visibility: hidden;
    opacity: 0;
    color: var(--color-neutrals-white);
    content: attr(data-tooltip);
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-decoration: none;
    white-space: nowrap;
  }

  [data-tooltip]:hover:before {
    visibility: visible;
    opacity: 1;
    text-decoration: none;
    transition: .3s ease;
    margin-left: 38px;
  }
}

.pointerEvents {
  pointer-events: auto;
}

.wrapper {
  overflow: hidden;
  width: 45px;
  cursor: pointer;
}

.container {
  position: fixed;
  right: 26px;
  bottom: 182px;
  z-index: 99;
  width: 60px;
  cursor: pointer;

  @media (max-width: 768px) {
    display: none;
  }
}

.facebook {
  position: absolute;
  top: -180px;
  right: 15px;
  background: #1877F2;
}

.fbIcon {
  position: absolute;
  left: 17px;
  top: 10px;
}

.whatsapp {
  position: absolute;
  top: 0;
  right: 15px;
  background: #6BDC4A;
}

.waIcon {
  position: absolute;
  left: 11px;
  top: 9px;
}

.chat {
  position: absolute;
  top: -60px;
  right: 15px;
  background: var(--color-brand-primary-shade);

  &>button {
    background: inherit;
    border: none;
    outline: none;
  }
}

.chatIcon {
  position: absolute;
  left: 11px;
  top: 14px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44.3399543762207px;
  width: 44.8546257019043px;
  border-radius: 50px;
  transition: .1s ease;
  overflow: hidden;

  &:hover {
    width: 186px;
    height: 45px;
    border-radius: 30px;
    transition: .5s ease;
    padding-left: 5px;
  }
}

.svg {
  margin-bottom: 16px;
}

$list: (
  first: 0.6,
  second: 0.4,
  third: 0.2,
  closeFirst: 0.2,
  closeSecond: 0.4,
  closeThird: 0.6
);

@each $name, $count in $list {
  .#{$name} {
    pointer-events: inherit;
    animation: #{$name} #{$count}s ease;
    animation-fill-mode: both;
  }
}

$animeList: (
  first: 150,
  second: 100,
  third: 50
);

@each $name, $value in $animeList {
  @keyframes #{$name} {
    from {
      transform: translateY(#{$value}px);
      opacity: 0;
    }

    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

$animationListEnd: (
  closeFirst: 150,
  closeSecond: 100,
  closeThird: 50
);

@each $name, $value in $animationListEnd {
  @keyframes #{$name} {
    0% {
      transform: translateY(0);
    }

    99% {
      transform: translateY(#{$value}px);
    }

    100% {
      opacity: 0;
    }
  }
}

.mount {
  visibility: visible;
}

.popap {
  display: flex;
  gap: 12px;

  @media (max-width: 1080px) {
    justify-content: center;
  }

  &__icon {
    width: 40px !important;
    height: 40px !important;
  }
}
